import React from "react"

type Props = {
  onClickFunc?: (e: React.MouseEvent<SVGSVGElement> | undefined) => void
}

const UpdateIcon: React.FC<Props> = ({ onClickFunc }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClickFunc}
    >
      <path
        d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.04 3.01999L8.16 10.9C7.86 11.2 7.56 11.79 7.5 12.22L7.07 15.23C6.91 16.32 7.68 17.08 8.77 16.93L11.78 16.5C12.2 16.44 12.79 16.14 13.1 15.84L20.98 7.95999C22.34 6.59999 22.98 5.01999 20.98 3.01999C18.98 1.01999 17.4 1.65999 16.04 3.01999Z"
        stroke="#292D32"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.91 4.14999C15.58 6.53999 17.45 8.40999 19.85 9.08999"
        stroke="#292D32"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UpdateIcon
