import React, { useEffect, useMemo, useState } from "react"
import styles from "./builderId.module.scss"
import {
  Button,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Spin,
  Tabs,
} from "antd"
import { useNavigate, useParams } from "react-router-dom"
import {
  useGetDistrictswithoutcountcomplexes,
  useGetOneBuilder,
  useGetRegionList,
} from "../../../../queries/queries"
import {
  useCreateBuilder,
  useDeleteBuilder,
  useUpdateBuilder,
} from "../../../../queries/mutations"
import { BuilderFormFields } from "../../../../utils/models/buildersModel"
import { STRING } from "../../../../utils/constants/types"
import { rootPaths } from "../../../../routing/root/rootPaths"
import ArrowLeftIcon from "../../../../assets/icons/ArrowLeftIcon"
import TrashIcon from "../../../../assets/icons/TrashIcon"
import DetailedBuilderImage from "../../../../components/builders/detailed/image/DetailedBuilderImage"
import { InputMask } from "../../../../common/inputMask/InputMask"
import { PHONE_MASK } from "../../../../utils/constants/inputMasks"
import { phoneNumberValidator } from "../../../../utils/helpers/phoneNumberValidator"
import AddPhoneIcon from "../../../../assets/icons/AddPhoneIcon"
import RemovePhoneIcon from "../../../../assets/icons/RemovePhoneIcon"
import { ValidateErrorEntity } from "rc-field-form/es/interface"

const { Item, List } = Form
const { TextArea } = Input

const BuilderId = () => {
  const [activeTab, setActiveTab] = useState<"uz" | "ru">("uz")
  const [formInstance] = Form.useForm()
  const params = useParams<{ id: string }>()
  const navigate = useNavigate()
  const { data, isLoading } = useGetOneBuilder(Number(params.id))
  const { data: regionList } = useGetRegionList()
  const city_id = Form.useWatch("city_id", formInstance)
  const { data: districts } = useGetDistrictswithoutcountcomplexes(
    Number(city_id)
  )
  const createBuilder = useCreateBuilder(handleAfterSuccess)
  const updateBuilder = useUpdateBuilder(handleAfterSuccess)
  const deleteBuilder = useDeleteBuilder(handleAfterSuccess)

  // initial fields
  useEffect(() => {
    if (data) {
      formInstance.setFieldsValue({
        ...data,
        city_id: data?.city_id || null,
        district_id: data?.district_id || null,
      })
    }
  }, [data, formInstance])

  // clear fields
  useEffect(() => {
    return () => {
      formInstance.resetFields()
    }
  }, [formInstance])

  // go back
  const goBack = () => {
    navigate(-1)
  }

  // is updating
  const isUpdating = useMemo(() => !!Number(params.id), [params.id])

  // on finish
  const onFinish = (fields: BuilderFormFields) => {
    const formData = new FormData()

    let fieldsCopy = {
      ...(data && data),
      ...fields,
    }
    let key: keyof typeof fieldsCopy
    // remove image
    if (typeof fieldsCopy.image === STRING) delete fieldsCopy.image

    for (key in fieldsCopy) {
      if (Array.isArray(fieldsCopy[key])) {
        formData.append(key, (fieldsCopy[key] as Array<string>)?.join())
      } else {
        formData.append(key, fieldsCopy[key] as string | Blob)
      }
    }
    if (data) {
      formData.append("builder_id", params.id!)

      updateBuilder.mutate(formData)
    } else {
      createBuilder.mutate(formData)
    }
  }

  // on finish failed
  const onFinishFailed = (
    errorInfo: ValidateErrorEntity<BuilderFormFields>
  ) => {
    const errorField = errorInfo.errorFields[0]
    let fieldName = errorField.name[0] as string
    const fieldIndex = errorField.name[1] as string

    if (fieldName === "phone") fieldName += `_${fieldIndex}`

    document
      .getElementById(fieldName)
      ?.scrollIntoView({ block: "center", behavior: "smooth" })
  }

  // handle delete
  const handleDelete = () => {
    deleteBuilder.mutate({
      builder_id: params.id!,
    })
  }

  // handle after success
  function handleAfterSuccess() {
    navigate(rootPaths.BUILDERS)
  }

  return (
    <div className={styles.container}>
      <div className={styles.container_header}>
        <Button className={styles.back} onClick={goBack}>
          <ArrowLeftIcon />
          <span>“Quruvchilar” ga qaytish</span>
        </Button>
        {isUpdating && (
          <Popconfirm
            title="O'chirmoqchimisiz?"
            onConfirm={handleDelete}
            okText="Ha"
            cancelText="Yo'q"
          >
            <Button className={styles.delete} loading={deleteBuilder.isLoading}>
              <TrashIcon />
              <span>O’chirish</span>
            </Button>
          </Popconfirm>
        )}
      </div>
      <Spin spinning={isUpdating && isLoading}>
        <Tabs
          onChange={(key) => setActiveTab(key as any)}
          activeKey={activeTab}
        >
          <Tabs.TabPane tab="O'zbekcha" key="uz" />
          <Tabs.TabPane tab="Ruscha" key="ru" />
        </Tabs>
        <Form
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          form={formInstance}
          onFinishFailed={onFinishFailed}
          className={styles.container_body}
        >
          <DetailedBuilderImage data={data} />
          <Item
            name="name"
            label="Quruvchi nomi"
            rules={[{ required: true, message: "" }]}
          >
            <Input />
          </Item>
          <Item
            name="brand_name"
            label="Brand nomi"
            rules={[{ required: true, message: "" }]}
          >
            <Input />
          </Item>

          <Item name={"address"} label="Sotuv ofisi manzili">
            <Input />
          </Item>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Item
                name="city_id"
                label="Viloyat"
                // rules={[{ required: true, message: "" }]}
              >
                <Select
                  className={"customSelect"}
                  onChange={() => {
                    formInstance.setFieldsValue({ district_id: null })
                  }}
                  allowClear
                >
                  {regionList?.map((item) => (
                    <Select.Option value={item?.id} key={item?.id}>
                      {item?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={12}>
              <Item
                name="district_id"
                label="Tuman"
                // rules={[{ required: true, message: "" }]}
              >
                <Select className={"customSelect"} allowClear>
                  {districts?.map((item) => (
                    <Select.Option value={item?.id} key={item?.id}>
                      {item?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
          </Row>
          <List name="phones" initialValue={[""]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} className={styles.phone_cont}>
                    <Item
                      {...(key === 0 && { label: "Telefon raqami" })}
                      rules={[phoneNumberValidator()]}
                      initialValue=""
                      name={[name]}
                      {...restField}
                    >
                      {InputMask({
                        mask: PHONE_MASK,
                      })}
                    </Item>
                    {key === 0 ? (
                      <AddPhoneIcon
                        className={styles.add_icon}
                        onClickFunc={add}
                      />
                    ) : (
                      <RemovePhoneIcon onClickFunc={() => remove(name)} />
                    )}
                  </div>
                ))}
              </>
            )}
          </List>
          {activeTab === "uz" ? (
            <div className={styles.metaElements}>
              <Item
                name="description_uz"
                label="Quruvchi haqida [uz]"
                rules={[{ required: true, message: "" }]}
              >
                <TextArea />
              </Item>
              <Item label="Meta taglar [uz]" name={"meta_tags_uz"}>
                <Input />
              </Item>
              <Item label="Meta description [uz]" name="meta_description_uz">
                <TextArea autoSize={true} />
              </Item>
            </div>
          ) : (
            <div className={styles.metaElements}>
              <Item
                name="description_ru"
                label="Quruvchi haqida [ru]"
                rules={[{ required: true, message: "" }]}
              >
                <TextArea />
              </Item>
              <Item label="Meta taglar [ru]" name={"meta_tags_ru"}>
                <Input />
              </Item>
              <Item label="Meta description [ru]" name="meta_description_ru">
                <TextArea autoSize={true} />
              </Item>
            </div>
          )}
          <Button
            type="primary"
            htmlType="submit"
            className={styles.submit_btn}
            loading={createBuilder.isLoading || updateBuilder.isLoading}
          >
            Saqlash
          </Button>
        </Form>
      </Spin>
    </div>
  )
}

export default BuilderId
