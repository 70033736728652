import React from "react"
import { Pagination } from "antd"
import { stringify, parse } from "query-string"
import { useLocation, useNavigate } from "react-router-dom"

import { PAGE } from "../../utils/constants/queryParamsKeys"
import PaginationPrevIcon from "../../assets/icons/PaginationPrevIcon"
import PaginationNextIcon from "../../assets/icons/PaginationNextIcon"
import PaginationJumpPrevIcon from "../../assets/icons/PaginationJumpPrevIcon"
import PaginationJumpNextIcon from "../../assets/icons/PaginationJumpNextIcon"

import styles from "./CustomPagination.module.scss"

type Props = {
  total: number | undefined
  pageSize?: number
}

const CustomPagination: React.FC<Props> = ({ total, pageSize }) => {
  const navigate = useNavigate()
  const { search } = useLocation()

  // handle page
  const handlePage = (page: number) => {
    const newSearch = { ...parse(search), page }

    navigate({ search: stringify(newSearch) })
  }

  return (
    <div className={styles.pagination}>
      <Pagination
        current={Number(parse(search)[PAGE]) || 1}
        total={total}
        pageSize={pageSize ?? 10}
        showSizeChanger={false}
        onChange={handlePage}
        prevIcon={<PaginationPrevIcon />}
        nextIcon={<PaginationNextIcon />}
        jumpPrevIcon={<PaginationJumpPrevIcon />}
        jumpNextIcon={<PaginationJumpNextIcon />}
        hideOnSinglePage={true}
        showTitle={false}
      />
    </div>
  )
}

export default CustomPagination
