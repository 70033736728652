import React from "react"

import styles from "./placementModal.module.scss"
import ArrowDownIcon from "../../../../assets/icons/ArrowDownIcon"
import { Button } from "antd"
import TrashIcon1 from "../../../../assets/icons/TrashIcon1"
import { NearestplacesModel } from "../../../../utils/models/NearestplacesModel"
import { useDeletePlacement } from "../../../../queries/mutations"

type Props = {
  item: NearestplacesModel
}

const placementValues = {
  metro: "Metro",
  shopping_centre: "Savdo markazi",
  park: "Istirohat bog'i",
  on_foot: "Piyoda",
  on_bus: "Avtobusda",
  in_car: "Mashinada",
}

const PlacementItem: React.FC<Props> = ({ item }) => {
  const deletePlacement = useDeletePlacement()
  const handleDeletePlacement = (id: number) => {
    deletePlacement.mutate(id)
  }
  return (
    <div className={styles.placementDatas}>
      <div className={styles.placementDatas_item}>
        <p>Joy</p>
        <div>
          <span>
            {placementValues[item?.facility as keyof typeof placementValues]}
          </span>
          <ArrowDownIcon />
        </div>
      </div>
      <div className={styles.placementDatas_item}>
        <p>Name [UZ]</p>
        <div>
          <span>{item?.name_uz}</span>
        </div>
      </div>
      <div className={styles.placementDatas_item}>
        <p>Name [RU]</p>
        <div>
          <span>{item?.name_ru}</span>
        </div>
      </div>
      <div className={styles.placementDatas_item}>
        <p>Transport</p>
        <div>
          <span>
            {
              placementValues[
                item?.transportation as keyof typeof placementValues
              ]
            }
          </span>
          <ArrowDownIcon />
        </div>
      </div>
      <div className={styles.placementDatas_item}>
        <p>Vaqt</p>
        <div>
          <span>{item?.duration}</span>
        </div>
      </div>
      <div className={styles.placementDatas_item}>
        <p>Masofa</p>
        <div>
          <span>{item?.distance}</span>
        </div>
      </div>
      <Button
        className={styles.deleteIcon}
        onClick={() => handleDeletePlacement(item?.id!)}
        loading={deletePlacement.isLoading}
      >
        <TrashIcon1 />
      </Button>
    </div>
  )
}

export default PlacementItem
