import { RcFile } from "antd/lib/upload"

export type ApartmentFormFields = {
  floor: string
  rooms_count: string | number
  more_rooms?: string
  area: string
  price: string
  price_permission: boolean
  total_price: string
  total_price_permission: boolean
  rooms: ApartmentRoomType[]
  images?: Array<RcFile | ApartmentImageType>
}

export type ApartmentRes = {
  current_page: number
  total: number
  data: ApartmentType[]
}

export type ApartmentType = {
  apartment_id: number
  area: number
  block_id: number
  floor: string
  images?: ApartmentImageType[]
  price: number
  price_permission: number
  rooms_count: number
  status: string
  total_price: number
  total_price_permission: number
  rooms: ApartmentRoomType[]
}

export type ApartmentReq = {
  floor: string
  block_id: number
  rooms_count: number | string
  area: number
  price: number
  total_price: number
  price_permission: number
  total_price_permission: number
  rooms: ApartmentRoomType[]
}

export type ApartmentRoomType = {
  apartment_id?: number
  room_id: number
  area: number
  name: string
}

export type ApartmentImageType = {
  image_id: number
  small: string
  medium: string
  large: string
}

export enum ApartmentPermissions {
  pricePermission = "price_permisson",
  totalPricePermission = "total_price_permission",
}
