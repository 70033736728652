const StatisticsIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6667 15.1667H1.33337C1.06004 15.1667 0.833374 14.94 0.833374 14.6667C0.833374 14.3933 1.06004 14.1667 1.33337 14.1667H14.6667C14.94 14.1667 15.1667 14.3933 15.1667 14.6667C15.1667 14.94 14.94 15.1667 14.6667 15.1667Z"
        fill="#292D32" />
      <path
        d="M9.5 15.1667H6.5C6.22667 15.1667 6 14.94 6 14.6667V2.66667C6 1.52 6.63333 0.833333 7.7 0.833333H8.3C9.36667 0.833333 10 1.52 10 2.66667V14.6667C10 14.94 9.77333 15.1667 9.5 15.1667ZM7 14.1667H9V2.66667C9 1.9 8.64 1.83333 8.3 1.83333H7.7C7.36 1.83333 7 1.9 7 2.66667V14.1667Z"
        fill="#292D32" />
      <path
        d="M4.66667 15.1667H2C1.72667 15.1667 1.5 14.94 1.5 14.6667V6.66667C1.5 5.52 2.08667 4.83333 3.06667 4.83333H3.6C4.58 4.83333 5.16667 5.52 5.16667 6.66667V14.6667C5.16667 14.94 4.94 15.1667 4.66667 15.1667ZM2.5 14.1667H4.16667V6.66667C4.16667 5.83333 3.8 5.83333 3.6 5.83333H3.06667C2.86667 5.83333 2.5 5.83333 2.5 6.66667V14.1667Z"
        fill="#292D32" />
      <path
        d="M14 15.1667H11.3334C11.06 15.1667 10.8334 14.94 10.8334 14.6667V10C10.8334 8.85333 11.42 8.16667 12.4 8.16667H12.9334C13.9134 8.16667 14.5 8.85333 14.5 10V14.6667C14.5 14.94 14.2734 15.1667 14 15.1667ZM11.8334 14.1667H13.5V10C13.5 9.16667 13.1334 9.16667 12.9334 9.16667H12.4C12.2 9.16667 11.8334 9.16667 11.8334 10V14.1667Z"
        fill="#292D32" />
    </svg>
  )
}

export default StatisticsIcon;