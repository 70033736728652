import { Form, FormInstance, Input, Select } from "antd"
import React from "react"

import SelectSuffixIcon from "../../../../assets/icons/SelectSuffixIcon"
import { InputMask } from "../../../../common/inputMask/InputMask"
import { useGetClasses } from "../../../../queries/queries"
import { DATE_MASK } from "../../../../utils/constants/inputMasks"
import { validateDateString } from "../../../../utils/helpers/validateDateString"
import Map from "./Map"
import MapUpdate from "./MapUpdate"
import { useGetBuilders } from "../../../../queries/queries"
import Address from "./Address"

import styles from "../DetailedComplex.module.scss"

type Props = {
  formInstance: FormInstance
  coords: string[] | undefined
  city_id: number | undefined
}

const { Item } = Form
const { Option } = Select

const DetailedComlpexBasicInformation: React.FC<Props> = ({
  formInstance,
  coords,
  city_id,
}) => {
  const { data: builders, isLoading: isLoadingBuilders } = useGetBuilders()
  const { data: classes, isLoading: isLoadingClasses } = useGetClasses()

  return (
    <div className={styles.basic_information}>
      <p className={styles.little_title}>Asosiy ma’lumotlar</p>
      <div className={styles.first_row}>
        <Item
          label="Turar-joy majmuasining nomi"
          className={styles.name}
          name="name"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Item>
        <Item
          label="Topshirish muddati"
          className={styles.date}
          name="deadline"
          initialValue=""
          rules={[
            { required: true, message: "" },
            {
              validator: (_, value) => {
                if (value) {
                  formInstance.setFieldsValue({
                    year: validateDateString(value),
                  })
                }
                if (value.length !== 10) return Promise.reject()
                return Promise.resolve()
              },
            },
          ]}
        >
          {InputMask({ mask: DATE_MASK })}
        </Item>
      </div>
      <div className={styles.second_row}>
        <Item
          className={styles.builder}
          label="Quruvchini biriktirish"
          name="builder_id"
          rules={[{ required: true, message: "" }]}
        >
          <Select suffixIcon={<SelectSuffixIcon />} loading={isLoadingBuilders}>
            {builders?.map((builder) => (
              <Option value={builder.builder_id} key={builder.builder_id}>
                {builder.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          className={styles.complex_class}
          label="Turar-joy majmuasining sinfi"
          rules={[{ required: true, message: "" }]}
          name="class_id"
        >
          <Select suffixIcon={<SelectSuffixIcon />} loading={isLoadingClasses}>
            {classes?.map((item) => (
              <Option value={item?.id} key={item?.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
      </div>
      <Address city_id={city_id} formInstance={formInstance} />
      {coords ? (
        <MapUpdate
          formInstance={formInstance}
          coords={coords?.map((item) => +item)}
        />
      ) : (
        <Map formInstance={formInstance} coords={undefined} />
      )}
    </div>
  )
}

export default DetailedComlpexBasicInformation
