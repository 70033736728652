import React from "react"
import { ImageResize } from "quill-image-resize-module-ts"
import ReactQuill, { Quill } from "react-quill"
import { Form } from "antd"

import { FORMATS, MODULES, theme } from "../../../utils/models/quillElements"
import styles from "../detailed/DetailedMagazine.module.scss"

const JournalDescription = () => {
  // image resize for quill
  Quill.register("modules/imageResize", ImageResize)

  // font quill
  const Font = Quill.import("formats/font")
  Font.whitelist = ["Inter"]
  Quill.register(Font, true)

  return (
    <Form.Item
      name="description"
      label="Maqola matni"
      className={styles.form_desc}
      rules={[
        () => ({
          validator(_, value) {
            if (!value) return Promise.reject()
            return Promise.resolve()
          },
        }),
      ]}
    >
      <ReactQuill
        theme={theme.SNOW}
        formats={FORMATS}
        modules={MODULES}
        className="warning_border"
      />
    </Form.Item>
  )
}

export default JournalDescription
