import React from "react"
import { useAuth } from "../../queries/queries"

const IsAuth: React.FC = () => {
  useAuth()

  return null
}

export default IsAuth
