export const endPoints = {
  DASHBOARD_STORY_GROUP_VIEW: "/api/dashboard/story-group/view",
  DASHBOARD_STORY_GROUP_CREATE: "/api/dashboard/story-group/create",
  DASHBOARD_STORY_GROUP_UPDATE: "/api/dashboard/story-group/update",
  DASHBOARD_STORY_GROUP_STATUS: "/api/dashboard/story-group/status",
  DASHBOARD_STORY_GROUP_DELETE: (id: number | string) => {
    return `/api/dashboard/story-group/delete?id=${id}`
  },
  DASHBOARD_STORY_CREATE: "/api/dashboard/story/create",
  DASHBOARD_STORY_VIEW: "/api/dashboard/story/view",
  DASHBOARD_STORY_DELETE: (id: number | string) => {
    return `/api/dashboard/story/delete?story_id=${id}`
  },
}
