import React, { useEffect } from "react"
import styles from "./storyId.module.scss"
import { Button, Form, Input } from "antd"
import ArrowLeftIcon from "../../../../assets/icons/ArrowLeftIcon"
import ImageUpload from "../../../../common/image-upload/ImageUpload"
import {
  useStoryGroupCreate,
  useStoryGroupUpdate,
} from "../../services/mutation"
import { useDashboardStoryGroupViewId } from "../../services/queries"
import { useNavigate, useParams } from "react-router-dom"
import { rootPaths } from "../../../../routing/root/rootPaths"

const StoryId = () => {
  const [form] = Form.useForm()
  const params = useParams()
  const navigate = useNavigate()
  const id = params?.id === "new" ? "" : params?.id
  const { data } = useDashboardStoryGroupViewId(id)

  const storyGroupCreate = useStoryGroupCreate()
  const storyGroupUpdate = useStoryGroupUpdate()
  const onFinish = (values: any) => {
    const formData = new FormData()

    const fields = {
      ...values,
      ...(id && { id }),
    }

    if (typeof fields.image === "string") delete fields.image

    Object.keys(fields).map((key) => {
      formData.append(key, fields[key])
    })

    if (id) {
      storyGroupUpdate.mutate(formData)
    } else {
      storyGroupCreate.mutateAsync(formData).then((res) => {
        navigate(rootPaths.STORY)
      })
    }
  }

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
      })
    }
  }, [data])

  return (
    <div className={styles.container}>
      <Button
        className={styles.backBtn}
        onClick={() => navigate(rootPaths.STORY)}
      >
        <ArrowLeftIcon /> “Tarix” ga qaytish
      </Button>

      <div className={styles.body}>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item
            label="Intro"
            name={"image"}
            rules={[{ required: !id, message: "" }]}
          >
            <ImageUpload />
          </Form.Item>
          <Form.Item
            label="Kategoriya nomi [uz]"
            name={"name_uz"}
            rules={[{ required: !id, message: "" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Kategoriya nomi [ru]"
            name={"name_ru"}
            rules={[{ required: !id, message: "" }]}
          >
            <Input />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={storyGroupUpdate.isLoading || storyGroupCreate.isLoading}
          >
            Saqlash
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default StoryId
