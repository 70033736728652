import React, { useState } from "react"
import { useGetPlacements } from "../../../../queries/queries"
import { useCreatePlacement } from "../../../../queries/mutations"
import { NearestplacesModel } from "../../../../utils/models/NearestplacesModel"
import { Button, Form, Input, Modal, Select } from "antd"
import { localeFormatter } from "../../../../utils/helpers/localeFormatter"
import { parseLocaledString } from "../../../../utils/helpers/parseLocaledString"
import PlusCircleIcon from "../../../../assets/icons/PlusCircleIcon"
import PlacementItem from "./PlacementItem"

import styles from "./placementModal.module.scss"

type Props = {
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  regionId: number
}

const { Option } = Select

const placement = [
  {
    id: 1,
    label: "Metro",
    value: "metro",
  },
  {
    id: 2,
    label: "Savdo markazi",
    value: "shopping_centre",
  },
  {
    id: 3,
    label: "Istirohat bog'i",
    value: "park",
  },
]
const transportation = [
  {
    id: 1,
    label: "Piyoda",
    value: "on_foot",
  },
  {
    id: 2,
    label: "Avtobusda",
    value: "on_bus",
  },
  {
    id: 3,
    label: "Mashinada",
    value: "in_car",
  },
]

const PlacementsModal: React.FC<Props> = ({
  openModal,
  setOpenModal,
  regionId,
}) => {
  const [form] = Form.useForm()
  const { data } = useGetPlacements(regionId)
  const createPlacement = useCreatePlacement()

  const [createActive, setCreateActive] = useState(false)

  const handleOpenCreate = () => {
    setCreateActive((prev) => !prev)
    form.resetFields()
  }

  const onFinish = (values: NearestplacesModel) => {
    createPlacement
      .mutateAsync({
        complex_id: regionId,
        nearest_places: [
          {
            ...values,
            distance: parseLocaledString(String(values?.distance)),
            duration: parseLocaledString(String(values?.duration)),
          },
        ],
      })
      .then(() => {
        setCreateActive(false)
        form.resetFields()
      })
  }

  const handleClose = () => {
    setOpenModal(false)
    form.resetFields()
    setCreateActive(false)
  }

  const handlePriceFields = (field: string) => {
    const value = form.getFieldValue([field])

    form.setFieldsValue({
      [field]: localeFormatter(value),
    })
  }

  return (
    <Modal
      open={openModal}
      onCancel={handleClose}
      title="Joylar (Facilities)"
      width={1200}
      footer={false}
      className={styles.placementModal}
    >
      <div className={styles.allPlacements}>
        {data?.map((item) => {
          return <PlacementItem item={item} />
        })}
        {createActive && (
          <Form
            layout="vertical"
            form={form}
            autoComplete="off"
            onFinish={onFinish}
          >
            <Form.Item name="facility" label="Joy">
              <Select placeholder="Joy tanlang">
                {placement?.map((item) => (
                  <Option key={item?.id} value={item?.value}>
                    {item?.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="name_uz" label="Name [UZ]">
              <Input placeholder="Manzil kiriting" />
            </Form.Item>
            <Form.Item name="name_ru" label="Name [RU]">
              <Input placeholder="Manzil kiriting" />
            </Form.Item>
            <Form.Item name="transportation" label="Transport">
              <Select placeholder="Transport tanlang">
                {transportation?.map((item) => (
                  <Option key={item?.id} value={item?.value}>
                    {item?.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="duration" label="Vaqt">
              <Input
                placeholder="Vaqtni kiriting"
                onChange={() => handlePriceFields("duration")}
                addonAfter="min"
                className={styles.addonInput}
              />
            </Form.Item>
            <Form.Item label="Masofa" name="distance">
              <Input
                placeholder="Masofani kiriting"
                onChange={() => handlePriceFields("distance")}
                addonAfter="metr"
                className={styles.addonInput}
              />
            </Form.Item>
          </Form>
        )}
      </div>
      <div className={styles.createIcon} onClick={handleOpenCreate}>
        <PlusCircleIcon />
        <span>Yaqin oradagi manzillarni qo’shish</span>
      </div>
      <div className={styles.addBtn}>
        <Button
          htmlType="submit"
          onClick={() =>
            form.getFieldValue("facility") && onFinish(form.getFieldsValue())
          }
          loading={createPlacement.isLoading}
        >
          Qo'shish
        </Button>
      </div>
    </Modal>
  )
}

export default PlacementsModal
