import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RcFile } from "antd/lib/upload"
import { sliceNames } from "../../utils/constants/sliceNames"
import {
  ApartmentImageType,
  ApartmentRoomType,
} from "../../utils/models/apartmentModel"

const initialState: {
  rooms: ApartmentRoomType[]
  images: Array<ApartmentImageType | RcFile>
} = {
  rooms: [],
  images: [],
}

const apartmentReducer = createSlice({
  name: sliceNames.APARTMENT,
  initialState,
  reducers: {
    addRoom: (state, action: PayloadAction<ApartmentRoomType>) => {
      state.rooms.push(action.payload)
    },
    setRooms: (state, action: PayloadAction<ApartmentRoomType[]>) => {
      state.rooms = action.payload
    },
    deleteRoom: (state, action: PayloadAction<number>) => {
      state.rooms = state.rooms.filter(
        (room) => room.room_id !== action.payload
      )
    },

    // images
    addImage: (state, action: PayloadAction<RcFile>) => {
      state.images.push(action.payload)
    },
    setImages: (
      state,
      action: PayloadAction<Array<ApartmentImageType | RcFile>>
    ) => {
      state.images = action.payload
    },
    deleteImage: (state, action: PayloadAction<number>) => {
      state.images.splice(action.payload, 1)
    },
  },
})

export default apartmentReducer.reducer
export const apartmentReducerActions = apartmentReducer.actions
