import React from "react"
import { Switch } from "antd"

import { useChangeStatusArticle } from "../../../queries/mutations"
import { statuses, statusNames } from "../../../utils/constants/statuses"

type Props = {
  status: string
  article_id: number
}

const MagazinesTableSwitch = (props: Props) => {
  const changeStatus = useChangeStatusArticle()

  // change switch
  const onChange = (
    _: unknown,
    e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    changeStatus.mutate(props.article_id)
    e.stopPropagation()
  }

  return (
    <Switch
      checked={props.status === statusNames.ACTIVE ?? statuses.active}
      loading={changeStatus.isLoading}
      onChange={onChange}
    />
  )
}

export default MagazinesTableSwitch
