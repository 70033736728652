import { useMutation, useQueryClient } from "@tanstack/react-query"
import { $api2 } from "../../../services/RequestService"
import { endPoints } from "../utils/constants/endPoints"
import { queryKeys } from "../utils/constants/queryKeys"
import { StoryStatusType } from "../utils/constants/StoryStatusType"

export function useStoryGroupCreate() {
  const qc = useQueryClient()
  return useMutation({
    mutationFn: async (data: FormData) => {
      const res = await $api2.post(endPoints.DASHBOARD_STORY_GROUP_CREATE, data)
      return res.data
    },
    onSuccess() {
      qc.invalidateQueries([queryKeys.DASHBOARD_STORY_GROUP_VIEW])
    },
  })
}

export function useStoryGroupUpdate() {
  const qc = useQueryClient()
  return useMutation({
    mutationFn: async (data: FormData) => {
      const res = await $api2.post(endPoints.DASHBOARD_STORY_GROUP_UPDATE, data)
      return res.data
    },
    onSuccess() {
      qc.invalidateQueries([queryKeys.DASHBOARD_STORY_GROUP_VIEW])
    },
  })
}

export function useStoryGroupStatus() {
  const qc = useQueryClient()
  return useMutation({
    mutationFn: async (data: { id: number; status: StoryStatusType }) => {
      const res = await $api2.put(endPoints.DASHBOARD_STORY_GROUP_STATUS, data)
      return res.data
    },
    onSuccess() {
      qc.invalidateQueries([queryKeys.DASHBOARD_STORY_GROUP_VIEW])
    },
  })
}

export function useStoryGroupDelete() {
  const qc = useQueryClient()
  return useMutation({
    mutationFn: async (id: number) => {
      const res = await $api2.delete(endPoints.DASHBOARD_STORY_GROUP_DELETE(id))
      return res.data
    },
    onSuccess() {
      qc.invalidateQueries([queryKeys.DASHBOARD_STORY_GROUP_VIEW])
    },
  })
}

export function useStoryCreate() {
  const qc = useQueryClient()
  return useMutation({
    mutationFn: async (data: FormData) => {
      const res = await $api2.post(endPoints.DASHBOARD_STORY_CREATE, data)
      return res.data
    },
    onSuccess() {
      qc.invalidateQueries([queryKeys.DASHBOARD_STORY_VIEW])
    },
  })
}

export function useStoryDelete() {
  const qc = useQueryClient()
  return useMutation({
    mutationFn: async (story_id: number | string) => {
      const res = await $api2.delete(endPoints.DASHBOARD_STORY_DELETE(story_id))
      return res.data
    },
    onSuccess() {
      qc.invalidateQueries([queryKeys.DASHBOARD_STORY_VIEW])
    },
  })
}
