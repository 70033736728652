import { LocalStorage } from '../../services/LocalStorage'
import { PERMISSIONS } from '../constants/localStorageKeys'
import { permissions } from '../enums/permissions'

export const hasPermission = (neededPermission: permissions) => {
  const userPermissions = LocalStorage.get<permissions[]>(PERMISSIONS)

  if (userPermissions?.includes(neededPermission)) {
    return true
  }
  return false
}
