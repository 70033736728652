import { useQuery } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"

import { rootPaths } from "../routing/root/rootPaths"
import $api, { $api2 } from "../services/RequestService"
import {
  apartmentEndpoints,
  authEndpoints,
  blockEndpoints,
  builderEndpoints,
  cityEndpoints,
  classEndpoints,
  complexEndpoints,
  magazineEndpoints,
  nearestplacesEndpoints,
  paymentMethodEndpoints,
} from "../utils/constants/apis"
import { queryKeys } from "../utils/constants/queryKeys"
import {
  BLOCK_ID,
  BUILDER_ID,
  CITY_ID,
  COMPLEX_ID,
  MAGAZINE_ID,
  PAGE,
} from "../utils/constants/queryParamsKeys"
import { errorHandler } from "../utils/helpers/errorHandler"
import { CityType, DistrictType } from "../utils/models/addressModel"
import { ApartmentRes } from "../utils/models/apartmentModel"
import { BlockRes } from "../utils/models/blockModel"
import { BuilderType } from "../utils/models/buildersModel"
import { ComplexRes, ComplexType } from "../utils/models/complexModel"
import {
  Category,
  MagazineRes,
  MagazineType,
} from "../utils/models/magazineType"
import { PaymentMethodModel } from "../utils/models/paymentMethodModel"
import { ErrorRes } from "../utils/models/responseType"
import { endPoints } from "../utils/constants/endPoints"
import { Region } from "../utils/models/Region"
import { NearestplacesModel } from "../utils/models/NearestplacesModel"

// is auth
export function useAuth() {
  const navigate = useNavigate()

  return useQuery<boolean, ErrorRes>(
    [queryKeys.IS_AUTH],
    async () => {
      const res = await $api.get(authEndpoints.INDEX)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: () => {
        window.location.href = "https://uysot.uz"
      },
      onSuccess: () => {
        navigate(rootPaths.RESIDENTAL_COMPLEXES)
      },
    }
  )
}

// all articles
export function useGetMagazine(page: string | undefined) {
  return useQuery<MagazineRes, ErrorRes>(
    [queryKeys.MAGAZINE, page],
    async () => {
      const res = await $api.get(
        `${magazineEndpoints.GET}?${PAGE}=${page ?? 1}`
      )
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    }
  )
}

// get one article
export function useGetOneMagazine(magazine_id: number | undefined) {
  return useQuery<MagazineType, ErrorRes>(
    [queryKeys.MAGAZINE, magazine_id],
    async () => {
      const res = await $api.get(
        `${magazineEndpoints.GET}?${MAGAZINE_ID}=${magazine_id}`
      )
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!magazine_id,
      onError: errorHandler,
    }
  )
}

// get all category for article
export function useCategory() {
  return useQuery<Category[], ErrorRes>(
    [queryKeys.CATEGORY],
    async () => {
      const res = await $api.get(magazineEndpoints.CATEGORY)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    }
  )
}

// get builders
export function useGetBuilders() {
  return useQuery<BuilderType[], ErrorRes>(
    [queryKeys.BUILDERS],
    async () => {
      const res = await $api.get(builderEndpoints.GET)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    }
  )
}

// get one builder
export function useGetOneBuilder(builder_id: number | undefined) {
  return useQuery<BuilderType, ErrorRes>(
    [queryKeys.ONE_BUILDER, builder_id],
    async () => {
      const res = await $api.get(
        `${builderEndpoints.GET}?${BUILDER_ID}=${builder_id}`
      )
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!builder_id,
      onError: errorHandler,
    }
  )
}

// get residential complexes
export function useGetComplexes() {
  return useQuery<ComplexRes[], ErrorRes>(
    [queryKeys.RESIDENTIAL_COMPLEXES],
    async () => {
      const res = await $api.get(complexEndpoints.GET)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    }
  )
}

// get one residential complex
export function useGetOneComplex(complex_id: number | undefined) {
  return useQuery<ComplexType, ErrorRes>(
    [queryKeys.ONE_RESIDENTIAL_COMPLEX, complex_id],
    async () => {
      const res = await $api.get(
        `${complexEndpoints.GET}?${COMPLEX_ID}=${complex_id}`
      )
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!complex_id,
      onError: errorHandler,
    }
  )
}

// get blocks
export function useGetBlocks(complex_id: string | undefined) {
  return useQuery<BlockRes[], ErrorRes>(
    [queryKeys.BLOCKS, complex_id],
    async () => {
      const res = await $api.get(
        `${blockEndpoints.GET}?${COMPLEX_ID}=${complex_id}`
      )
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!complex_id,
      onError: errorHandler,
    }
  )
}

// get classes
export function useGetClasses() {
  return useQuery<{ id: number; name: string }[], ErrorRes>(
    [queryKeys.CLASSES],
    async () => {
      const res = await $api.get(classEndpoints.SELECT)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    }
  )
}

// get apartments
export function useGetApartments(
  blockId: number | undefined,
  page: string | undefined
) {
  return useQuery<ApartmentRes, ErrorRes>(
    [queryKeys.APARTMENTS, blockId, page],
    async () => {
      const res = await $api.get(
        `${apartmentEndpoints.GET}?${BLOCK_ID}=${blockId}&${PAGE}=${page ?? 1}`
      )
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!blockId,
      onError: errorHandler,
    }
  )
}

// get cities
export function useGetCities() {
  return useQuery<CityType[], ErrorRes>(
    [queryKeys.CITIES],
    async () => {
      const res = await $api.get(cityEndpoints.INDEX)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    }
  )
}

// get districts
export function useGetDistricts(cityId: number | undefined) {
  return useQuery<DistrictType[], ErrorRes>(
    [queryKeys.DISTRICTS, cityId],
    async () => {
      const res = await $api.get(`${cityEndpoints.INDEX}?${CITY_ID}=${cityId}`)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!cityId,
      onError: errorHandler,
    }
  )
}

// get payment methods
export function useGetPaymentMethods() {
  return useQuery<PaymentMethodModel[], ErrorRes>(
    [queryKeys.PAYMENT_METHODS],
    async () => {
      const res = await $api.get(paymentMethodEndpoints.SELECT)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    }
  )
}

// get region list
export function useGetRegionList() {
  return useQuery<Region[], ErrorRes>(
    [queryKeys.REGION_LIST],
    async () => {
      const res = await $api2.get(endPoints.REGION_LIST)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    }
  )
}

// get district
export function useGetDistrictswithoutcountcomplexes(regionId: number) {
  return useQuery<Region[], ErrorRes>(
    [queryKeys.REGION_DISTRICTSWITHOUTCOUNTCOMPLEXES_ID, regionId],
    async () => {
      const res = await $api2.get(
        endPoints.REGION_DISTRICTSWITHOUTCOUNTCOMPLEXES_ID(regionId)
      )
      return res.data.data
    },
    {
      enabled: !!regionId,
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    }
  )
}

export function useGetPlacements(complexId: number) {
  return useQuery<NearestplacesModel[], ErrorRes>(
    [queryKeys.COMPLEX_NEARESTPLACES, complexId],
    async () => {
      const res = await $api.get(nearestplacesEndpoints.GET + `?complex_id=${complexId}`)
      return res.data.data
    },
    {
      enabled: !!complexId,
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    }
  )
}