import { Skeleton } from "antd"
import React from "react"

import styles from "./ComplexLoader.module.scss"

const ComplexLoader: React.FC = () => {
  return (
    <div className={styles.complex_loader}>
      <Skeleton active />
    </div>
  )
}

export default ComplexLoader
