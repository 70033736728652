import styles from "./storyChildsCard.module.scss"
import { StoryView } from "../../../../utils/models/StoryView"
import PlaceholderIcon from "../../../../../../assets/icons/PlaceholderIcon"
import EyeIcon from "../../../../../../assets/icons/EyeIcon"
import ShareIcon from "../../../../../../assets/icons/ShareIcon"
import TrushIcon from "../../../../../../assets/icons/TrushIcon"
import { useStoryDelete } from "../../../../services/mutation"
import { LoadingOutlined } from "@ant-design/icons"
import { getFileFormat } from "../../../../../../utils/helpers/getFileFormat"

const StoryChildsCard = (props: StoryView) => {
  const storyDeleteMutate = useStoryDelete()

  const handleCard = () => {
    // navigate(
    //   rootPaths.STORY +
    //     storyPaths.INDEX +
    //     params?.id +
    //     "/" +
    //     params?.name +
    //     "/" +
    //     props?.id
    // )
  }

  const handleDelete = () => {
    storyDeleteMutate.mutate(props?.id)
  }

  return (
    <div className={styles.card} onClick={handleCard}>
      {getFileFormat(props?.file).type === "video" ? (
        <video className={styles.video} onClick={(e) => e?.stopPropagation()}>
          <source
            src={props?.file}
            type={`${getFileFormat(props?.file).type}/${
              getFileFormat(props?.file).format
            }`}
          />
        </video>
      ) : (
        <img src={props?.file} alt="" draggable={false} />
      )}
      <span
        className={`${styles.trash} ${
          storyDeleteMutate.isLoading ? styles.trash_load : ""
        }`}
        onClick={handleDelete}
      >
        {storyDeleteMutate.isLoading ? (
          <LoadingOutlined style={{ fontSize: 20 }} />
        ) : (
          <TrushIcon />
        )}
      </span>
      <div className={styles.footer}>
        <span>
          <PlaceholderIcon /> {props?.count_clicked_button_story}
        </span>
        <span>
          <EyeIcon stroke="#000000" /> {props?.count_views_story}
        </span>
        {/* <span>
          <ShareIcon /> {props?.count_clicked_button_story}
        </span> */}
      </div>
    </div>
  )
}

export default StoryChildsCard
