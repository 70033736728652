import React, { useState } from "react"
import styles from "./story.module.scss"
import StoryHeader from "./components/story-header/StoryHeader"
import { Avatar, Button, Input, Table, TableColumnsType } from "antd"
import SearchIcon from "../../assets/icons/SearchIcon"
import PlusCircleIcon from "../../assets/icons/PlusCircleIcon"
import { useDashboardStoryGroupView } from "./services/queries"
import { StoryGroupView } from "./utils/models/StoryGroupView"
import { useNavigate } from "react-router-dom"
import { rootPaths } from "../../routing/root/rootPaths"
import { storyPaths } from "../../routing/story/storyPaths"
import HistoryAction from "./StoryAction"

const History = () => {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const navigate = useNavigate()
  const { data } = useDashboardStoryGroupView(page, search)

  const columns: TableColumnsType<StoryGroupView> = [
    {
      title: "Katalog nomi",
      dataIndex: "name_uz",
      render(value, record, index) {
        return (
          <>
            <Avatar src={record?.image} shape="square" /> {record?.name_uz}
          </>
        )
      },
    },
    {
      title: "Yaratilgan sana",
      dataIndex: "created_at",
      align: "right",
    },
    {
      title: "Holati",
      dataIndex: "status",
      render(value, record, index) {
        return <HistoryAction {...record} />
      },
      width: 200,
    },
  ]
  return (
    <div className={styles.container}>
      <StoryHeader />
      <div className={styles.filter}>
        <Input
          prefix={<SearchIcon />}
          placeholder="Qidiruv"
          className={styles.filter_search}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        <Button
          onClick={() => navigate(rootPaths.STORY + storyPaths.INDEX + "new")}
        >
          <PlusCircleIcon /> Kategoriya qo’shish
        </Button>
      </div>
      <Table
        dataSource={data?.data}
        columns={columns}
        scroll={{ y: `calc(100vh - 300px)` }}
        pagination={{
          total: data?.total,
          current: data?.current_page,
          onChange(page, pageSize) {
            setPage(page)
          },
        }}
        rowClassName={styles.tableRow}
        onRow={(record) => {
          return {
            onClick: () => {
              navigate(
                rootPaths.STORY +
                  storyPaths.INDEX +
                  record?.id +
                  "/" +
                  record?.name_uz
              )
            },
          }
        }}
      />
    </div>
  )
}

export default History
