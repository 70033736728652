import { Button, Empty } from "antd"
import React from "react"
import { useNavigate } from "react-router-dom"

import PlusIcon from "../../../assets/icons/PlusIcon"
import { useGetBuilders } from "../../../queries/queries"
import { rootPaths } from "../../../routing/root/rootPaths"
import { createNewArr } from "../../../utils/helpers/createNewArr"
import { isEmptyArr } from "../../../utils/helpers/isEmptyArr"
import BuilderCard from "../builderCard/BuilderCard"
import BuilderLoader from "../loader/BuilderLoader"

import styles from "./Builders.module.scss"

const Builders: React.FC = () => {
  const { data, isLoading } = useGetBuilders()
  const navigate = useNavigate()

  // to create builder
  const toCreateBuilder = () => {
    navigate(`${rootPaths.BUILDERS}/new`)
  }

  return (
    <div className={styles.container}>
      <div className={styles.container_header}>
        <h1>Quruvchilar</h1>
        <Button onClick={toCreateBuilder}>
          <PlusIcon />
          <span>Yangi yaratish</span>
        </Button>
      </div>
      <div className={styles.container_body}>
        {isLoading ? (
          createNewArr(3).map((_, index) => <BuilderLoader key={index} />)
        ) : !isEmptyArr(data) ? (
          data?.map((builder) => (
            <BuilderCard key={builder.builder_id} {...builder} />
          ))
        ) : (
          <Empty />
        )}
      </div>
    </div>
  )
}

export default Builders
