import React from "react"

const UploadImageIcon: React.FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.9599 1.33334H24.0399C22.8799 1.33334 22.0266 1.81334 21.6399 2.66668C21.4266 3.05334 21.3333 3.50668 21.3333 4.04001V7.96001C21.3333 9.65334 22.3466 10.6667 24.0399 10.6667H27.9599C28.4933 10.6667 28.9466 10.5733 29.3333 10.36C30.1866 9.97334 30.6666 9.12001 30.6666 7.96001V4.04001C30.6666 2.34668 29.6533 1.33334 27.9599 1.33334ZM29.2133 6.57334C29.0799 6.70668 28.8799 6.80001 28.6666 6.81334H26.7866V7.49334L26.7999 8.66668C26.7866 8.89334 26.7066 9.08001 26.5466 9.24001C26.4133 9.37334 26.2133 9.46668 25.9999 9.46668C25.5599 9.46668 25.1999 9.10668 25.1999 8.66668V6.80001L23.3333 6.81334C22.8933 6.81334 22.5333 6.44001 22.5333 6.00001C22.5333 5.56001 22.8933 5.20001 23.3333 5.20001L24.5066 5.21334H25.1999V3.34668C25.1999 2.90668 25.5599 2.53334 25.9999 2.53334C26.4399 2.53334 26.7999 2.90668 26.7999 3.34668L26.7866 4.29334V5.20001H28.6666C29.1066 5.20001 29.4666 5.56001 29.4666 6.00001C29.4533 6.22668 29.3599 6.41334 29.2133 6.57334Z"
        fill="#7C7C7C"
      />
      <path
        d="M12 13.84C13.7526 13.84 15.1733 12.4193 15.1733 10.6667C15.1733 8.9141 13.7526 7.49335 12 7.49335C10.2474 7.49335 8.82666 8.9141 8.82666 10.6667C8.82666 12.4193 10.2474 13.84 12 13.84Z"
        fill="#7C7C7C"
      />
      <path
        d="M27.9601 10.6667H27.3334V16.8133L27.1601 16.6667C26.1201 15.7733 24.4401 15.7733 23.4001 16.6667L17.8534 21.4267C16.8134 22.32 15.1334 22.32 14.0934 21.4267L13.6401 21.0533C12.6934 20.2267 11.1867 20.1467 10.1201 20.8667L5.13341 24.2133C4.84008 23.4667 4.66675 22.6 4.66675 21.5867V10.4133C4.66675 6.65332 6.65341 4.66666 10.4134 4.66666H21.3334V4.03999C21.3334 3.50666 21.4267 3.05332 21.6401 2.66666H10.4134C5.56008 2.66666 2.66675 5.55999 2.66675 10.4133V21.5867C2.66675 23.04 2.92008 24.3067 3.41341 25.3733C4.56008 27.9067 7.01341 29.3333 10.4134 29.3333H21.5868C26.4401 29.3333 29.3334 26.44 29.3334 21.5867V10.36C28.9467 10.5733 28.4934 10.6667 27.9601 10.6667Z"
        fill="#7C7C7C"
      />
    </svg>
  )
}

export default UploadImageIcon
