import { Button, Form, FormInstance, Upload } from "antd"
import { RcFile, UploadChangeParam, UploadFile } from "antd/lib/upload"
import React, { useState, useEffect } from "react"

import UploadImageIcon from "../../../../assets/icons/UploadImageIcon"
import { isEmptyArr } from "../../../../utils/helpers/isEmptyArr"
import ImageCont from "./ImageCont"
import { ComplexImageType } from "../../../../utils/models/complexModel"

import styles from "../DetailedComplex.module.scss"

type Props = {
  formInstance: FormInstance
  data: ComplexImageType[] | undefined
}

const { Dragger } = Upload
const { Item } = Form

const DetailedComplexImages: React.FC<Props> = ({ formInstance, data }) => {
  const [images, setImages] = useState<Array<RcFile | ComplexImageType>>([])

  // initial images
  useEffect(() => {
    if (isEmptyArr(images) && data) {
      setImages(data)
    }
  }, [data, images])

  // set images
  useEffect(() => {
    formInstance.setFieldsValue({
      images: images,
    })
  }, [formInstance, images])

  // handle drop change
  const handleDropChange = (e: UploadChangeParam<UploadFile>) => {
    const newImage = e.file.originFileObj

    newImage && setImages([...images, newImage])
  }

  return (
    <div className={styles.images}>
      <p className={styles.little_title}>Rasmlar</p>
      <div className={styles.images_body}>
        <div className={styles.images_body_top}>
          <div className={styles.images_body_top_left}>
            <Item
              name="images"
              rules={[
                () => ({
                  validator(_, value) {
                    if (value.length === 0) return Promise.reject()
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <Dragger
                className={styles.dragger}
                showUploadList={false}
                onChange={handleDropChange}
                customRequest={() => null}
                accept=".jpg, .jpeg, .png"
              >
                <UploadImageIcon />
                <p className={styles.description}>Drag and drop photos here</p>
                <p className={styles.accept}>
                  Yuborish mumkin fayllar: PNG, JPEG, JPG
                </p>
                <Button>Fayl tanlash</Button>
                <p className={styles.max_size}>Maksimal hajm: 10 Mb</p>
              </Dragger>
            </Item>
          </div>
          <div className={styles.images_body_top_right}>
            <p className={styles.title}>
              Yuqori sifatli fotosuratlar xaridorlarni o'ziga jalb qiladi
            </p>
            <p className={styles.description}>
              3 tadan ko’p fotosurat qo’shing. Xonalar, oshxona, hammom,
              derazadan ko'rinish, kirish, binoning jabhasi, to'xtash joyini
              ko'rsating.
            </p>
          </div>
        </div>
        <div className={styles.images_body_bottom}>
          {images?.map((item, index) => (
            <ImageCont
              key={index}
              setImages={setImages}
              data={item}
              index={index}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default DetailedComplexImages
