import { useEffect, useState } from "react"
import { Modal } from "antd"
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

import styles from "./statistics.module.scss"
import { ComplexStatistic } from "../../../utils/models/complexModel"
import { StatisticsModel } from "../../../utils/models/StatisticsModel"

type Props = {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
  visible: boolean
  complex_id: number
  default_statistics: ComplexStatistic[]
  data: StatisticsModel[] | undefined
}

type LineDataType = {
  name: string
  dataKey: "eye" | "phone" | "lid" | "save"
  type: "linear"
}

const Statistics: React.FC<Props> = ({
  setVisible,
  visible,
  data,
  complex_id,
  default_statistics,
}) => {
  // graphics data
  const [chart, setChart] = useState<any[]>([])

  // close modal
  const onCancel = () => {
    setVisible(false)
  }

  // change res data
  useEffect(() => {
    if (data) {
      const keys: string[] = Object.keys(data[0].statistics)
      let defaultChart: any[] = []

      keys.forEach((item) => {
        defaultChart = [
          ...defaultChart,
          {
            dataKey: item,
            eye: data[0]?.statistics[item],
            save: data[1]?.statistics[item],
            phone: data[2]?.statistics[item],
            lid: data[3]?.statistics[item],
          },
        ]
      })

      setChart(defaultChart)
    }
  }, [data])

  // custom line data
  const lineData: LineDataType[] = [
    {
      name: "Ko'rinishlar",
      type: "linear",
      dataKey: "eye",
    },
    {
      name: "Saqlashlar",
      type: "linear",
      dataKey: "save",
    },
    {
      name: "Qo’ng’iroqlar",
      type: "linear",
      dataKey: "phone",
    },
    {
      name: "Lidlar",
      type: "linear",
      dataKey: "lid",
    },
  ]

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      className={styles.statistics}
      closeIcon={<></>}
    >
      <div className={styles.content}>
        <h1>Statistika</h1>
        <ResponsiveContainer width={700} height={500}>
          <LineChart data={chart}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="dataKey" padding={{ left: 30, right: 30 }} />
            <YAxis type="number" />
            <Tooltip
              content={(props) => {
                if (props && props.payload) {
                  const item = props?.payload
                  return (
                    <div className={styles.tooltip}>
                      {item?.map((event, index) => (
                        <div key={index} className={styles.tooltip_item}>
                          <div className={styles.name}>
                            <div
                              className={styles.icon}
                              style={{ background: event.color }}
                            />
                            {event.name}
                          </div>
                          <span>{event.value}</span>
                        </div>
                      ))}
                    </div>
                  )
                } else return <></>
              }}
            />
            <Legend
              content={(props) => {
                const { payload } = props
                return (
                  <div className={styles.legend}>
                    {payload?.map((item: any, index) => (
                      <div className={styles.legend_item} key={index}>
                        <div
                          className={styles.rectangle}
                          style={{ background: item.color }}
                        />
                        <span>{item.payload?.name ?? ""}</span>
                      </div>
                    ))}
                  </div>
                )
              }}
            />
            {data &&
              lineData.map((item, index) => (
                <Line
                  key={index}
                  name={item.name}
                  type={item.type}
                  dataKey={item.dataKey}
                  stroke={data[index]?.color}
                  strokeWidth={2}
                  activeDot={{ r: 8 }}
                />
              ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Modal>
  )
}

export default Statistics
