import { Button, Form, Input, Popconfirm, Popover, Spin, Tabs } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { LoadingOutlined } from "@ant-design/icons"

import ActionsIcon from "../../assets/icons/ActionsIcon"
import ArrowLeftIcon from "../../assets/icons/ArrowLeftIcon"
import BlockTrashIcon from "../../assets/icons/BlockTrashIcon"
import BlockUpdateIcon from "../../assets/icons/BlockUpdateIcon"
import {
  useCreateBlock,
  useDeleteBlock,
  useUpdateBlock,
} from "../../queries/mutations"
import { isEmptyArr } from "../../utils/helpers/isEmptyArr"
import Apartments from "./apartments/Apartments"
import { useGetBlocks } from "../../queries/queries"

import styles from "./Blocks.module.scss"

type FormFields = {
  name: string
  floors_count: number
}

const { Item } = Form

const Blocks: React.FC = () => {
  const navigate = useNavigate()
  const [formInstance] = Form.useForm()
  const params = useParams<{ id: string }>()
  const [activeKey, setActiveKey] = useState<string>(String(0))
  const { data: blocks, isLoading: isBlocksLoading } = useGetBlocks(params?.id)
  const [isCreating, setIsCreating] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const createBlock = useCreateBlock(afterSuccessCreate)
  const updateBlock = useUpdateBlock(afterSuccessUpdate)
  const deleteBlock = useDeleteBlock(afterSuccessDelete)

  // initial active key
  useEffect(() => {
    if (blocks && isEmptyArr(blocks)) {
      setActiveKey("new")
    }
  }, [blocks, activeKey])

  // initial isCreating
  useEffect(() => {
    if (isEmptyArr(blocks) && activeKey === "new") {
      setIsCreating(true)
    }
  }, [blocks, activeKey])

  // initial form fields
  useEffect(() => {
    if (activeKey && blocks) {
      formInstance.setFieldsValue({
        name: blocks[+activeKey]?.name,
        floors_count: blocks[+activeKey]?.floors_count,
      })
    } else {
      formInstance.resetFields()
    }
  }, [formInstance, activeKey, blocks])

  // go back
  const goBack = () => {
    navigate(-1)
  }

  // enable updating
  const enableUpdating = () => {
    setIsUpdating(true)
  }

  // handle delete
  const handleDelete = (blockId: number) => {
    deleteBlock.mutate(blockId)
  }

  // tab items
  const items = blocks?.map((item, index) => {
    return {
      label: (
        <>
          <p>{item.name}</p>
          <Popover
            overlayClassName={styles.block_popover}
            content={
              <div className={styles.block_popover_content}>
                <div
                  className={styles.block_popover_content_item}
                  onClick={enableUpdating}
                >
                  <BlockUpdateIcon />
                  <span>Tahrirlash</span>
                </div>
                <Popconfirm
                  title="Blokni o'chirmoqchimisiz?"
                  onConfirm={() => handleDelete(item.block_id)}
                  okText="Ha"
                  cancelText="Yo'q"
                  placement="right"
                  okButtonProps={{
                    loading: deleteBlock.isLoading,
                  }}
                  overlayClassName={styles.block_popconfirm}
                >
                  <div className={styles.block_popover_content_item}>
                    {deleteBlock.isLoading ? (
                      <Spin indicator={<LoadingOutlined spin />} />
                    ) : (
                      <BlockTrashIcon />
                    )}
                    <span>O’chirish</span>
                  </div>
                </Popconfirm>
              </div>
            }
            trigger="click"
            placement="right"
            mouseLeaveDelay={1000}
          >
            <span>
              <ActionsIcon />
            </span>
          </Popover>
        </>
      ),
      key: String(index),
    }
  })

  // handle change tabs
  const handleChangeTabs = (activeKey: string) => {
    setActiveKey(activeKey)

    // creating
    if (activeKey === "new") {
      setIsCreating(true)
    } else if (isCreating) {
      setIsCreating(false)
    }

    // updating
    if (isUpdating) {
      setIsUpdating(false)
    }
  }

  // on finish
  const onFinish = (fields: FormFields) => {
    const req = {
      ...fields,
      complex_id: +params?.id!,
    }

    if (activeKey === "new") {
      createBlock.mutate(req)
    } else {
      updateBlock.mutate({
        ...req,
        block_id: blocks![+activeKey].block_id,
      })
    }
  }

  // after success create
  function afterSuccessCreate() {
    setActiveKey(String(blocks?.length!))
    setIsCreating(false)
  }

  // after success update
  function afterSuccessUpdate() {
    setIsUpdating(false)
  }

  // after success delete
  function afterSuccessDelete() {
    setActiveKey(String(0))
  }

  // disable form
  const disableForm = useMemo(() => {
    if (isCreating || isUpdating) return false
    return true
  }, [isCreating, isUpdating])

  return (
    <div className={styles.container}>
      <div className={styles.container_header}>
        <Button className={styles.back} onClick={goBack}>
          <ArrowLeftIcon />
          <span>“Turar-joy majmualari” ga qaytish</span>
        </Button>
      </div>
      <div className={styles.container_body}>
        <Spin spinning={isBlocksLoading}>
          <Tabs
            activeKey={activeKey}
            items={items?.concat([
              {
                label: <p>+ Blok qo’shish</p>,
                key: "new",
              },
            ])}
            onChange={handleChangeTabs}
            className={styles.container_body_top}
          />
        </Spin>
        <Spin spinning={false}>
          <div className={styles.container_body_bottom}>
            <div className={styles.basic_info}>
              <p className={styles.little_title}>Asosiy ma’lumotlar</p>
              <Form
                layout="vertical"
                className={styles.basic_info_form}
                form={formInstance}
                autoComplete="off"
                onFinish={onFinish}
                disabled={disableForm}
              >
                <Item
                  label="Blok nomi"
                  name="name"
                  className={styles.name}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <Input />
                </Item>
                <Item
                  label="Qavatlar soni"
                  name="floors_count"
                  className={styles.floors_count}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <Input type="number" min={1} />
                </Item>
                <div
                  className={`${styles.submit_btn} ${
                    isCreating || isUpdating ? styles.active : ""
                  }`}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={createBlock.isLoading || updateBlock.isLoading}
                  >
                    Yaratish
                  </Button>
                </div>
              </Form>
            </div>
            {!isCreating && (
              <Apartments
                blockId={blocks ? blocks[+activeKey]?.block_id : undefined}
              />
            )}
          </div>
        </Spin>
      </div>
    </div>
  )
}

export default Blocks
