import Blocks from "../../components/blocks/Blocks"
import DetailedComplex from "../../components/complexes/detailed/DetailedComplex"
import Complexes from "../../components/complexes/main/Complexes"
import { RouteType } from "../../utils/models/routeModel"
import { complexesPaths } from "./complexesPaths"

export const complexesRoutes: RouteType[] = [
  {
    path: complexesPaths.INDEX,
    element: Complexes,
    isExact: true,
  },
  {
    path: complexesPaths.DETAILED,
    element: DetailedComplex,
    isExact: true,
  },
  {
    path: complexesPaths.BLOCKS,
    element: Blocks,
    isExact: true,
  },
]
