import { Button } from "antd"
import React, { useEffect, useState } from "react"
import ExclamanationIcon from "../../../assets/icons/ExclamanationIcon"

import styles from "./Popconfirm.module.scss"

type Props = {
  children: React.ReactNode
  onConfirm: () => void
  isLoading: boolean
}

const Popconfirm: React.FC<Props> = ({ children, onConfirm, isLoading }) => {
  const [visible, setVisible] = useState(false)

  // handle open
  const handleOpen = (e: React.MouseEvent<HTMLDivElement> | undefined) => {
    e?.stopPropagation()
    setVisible(true)
  }

  // handle close
  const handleClose = () => {
    setVisible(false)
  }

  useEffect(() => {
    window.addEventListener("click", handleClose)

    return () => window.removeEventListener("click", handleClose)
  }, [])

  // stop propagation
  const stopPropagation = (e: React.MouseEvent<HTMLDivElement> | undefined) => {
    e?.stopPropagation()
  }

  return (
    <div className={styles.custom_popconfirm} onClick={stopPropagation}>
      <div className={`${visible ? styles.active : ""} ${styles.popover}`}>
        <div className={styles.arrow}></div>
        <div className={styles.content}>
          <div className={styles.content_title}>
            <ExclamanationIcon />
            <span>O'chirmoqchimisiz?</span>
          </div>
          <div className={styles.content_buttons}>
            <Button size="small" onClick={handleClose}>
              Yo'q
            </Button>
            <Button
              loading={isLoading}
              type="primary"
              size="small"
              onClick={onConfirm}
            >
              Ha
            </Button>
          </div>
        </div>
      </div>
      <div onClick={handleOpen} className={styles.children_cont}>
        {children}
      </div>
    </div>
  )
}

export default Popconfirm
