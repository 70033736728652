import React from "react"

const PaginationJumpNextIcon: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1101_12)">
        <path
          d="M3 12L7 8L3 4"
          stroke="#A3A6B7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 12L13 8L9 4"
          stroke="#A3A6B7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1101_12">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(16 16) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PaginationJumpNextIcon
