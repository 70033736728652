import React, { useEffect } from "react"
import { Button, Form, Modal } from "antd"
import { ValidateErrorEntity } from "rc-field-form/es/interface"
import { RcFile } from "antd/lib/upload"

import ApartmentsAreaAndPrice from "./areaAndPrice/ApartmentsAreaAndPrice"
import ApartmentsModalFloor from "./floor/ApartmentsModalFloor"
import ApartmentsModalImages from "./images/ApartmentsModalImages"
import ApartmentsModalRooms from "./rooms/ApartmentsModalRooms"
import ApartmentsModalRoomsCount from "./roomsCount/ApartmentsModalRoomsCount"

import CloseIcon from "../../../assets/icons/CloseIcon"
import {
  ApartmentFormFields,
  ApartmentImageType,
  ApartmentReq,
  ApartmentType,
} from "../../../utils/models/apartmentModel"
import { parseLocaledString } from "../../../utils/helpers/parseLocaledString"
import {
  useCreateApartment,
  useUpdateApartment,
  useUploadImageApartment,
} from "../../../queries/mutations"
import { useAppDispatch } from "../../../hooks/reduxHooks"
import { apartmentReducerActions } from "../../../store/reducers/apartmentReducer"

import styles from "./ApartmentsModal.module.scss"

type Props = {
  visible: boolean
  setVisible: (visible: boolean) => void
  blockId: number | undefined
  data: ApartmentType | null
  setData: React.Dispatch<React.SetStateAction<ApartmentType | null>>
}

const ApartmentsModal: React.FC<Props> = ({
  setVisible,
  visible,
  blockId,
  data,
  setData,
}) => {
  const dispatch = useAppDispatch()
  const { setRooms, setImages } = apartmentReducerActions
  const [formInstance] = Form.useForm()
  const createApartment = useCreateApartment()
  const updateApartment = useUpdateApartment()
  const uploadImageApartment = useUploadImageApartment(afterSuccess)

  // initial form fields
  useEffect(() => {
    formInstance.setFieldsValue({
      ...data,
      price: data?.price?.toLocaleString(),
      price_permission: Boolean(data?.price_permission),
      total_price: data?.total_price?.toLocaleString(),
      total_price_permission: Boolean(data?.total_price_permission),
    })
  }, [data, formInstance])

  // handle cancel
  const handleCancel = () => {
    setVisible(false)
  }

  // handle submit
  const handleSubmit = () => {
    formInstance.submit()
  }

  // on finish
  const onFinish = (fields: ApartmentFormFields) => {
    const images = fields?.images?.slice() ?? []

    // delete unnecessary fields
    delete fields?.more_rooms
    delete fields?.images

    const req: ApartmentReq = {
      ...fields,
      area: +fields?.area,
      price: parseLocaledString(fields?.price),
      price_permission: Number(fields?.price_permission),
      total_price: parseLocaledString(fields?.total_price),
      total_price_permission: Number(fields?.total_price_permission),
      block_id: blockId!,
    }

    if (!data) {
      // create
      createApartment
        .mutateAsync(req)
        .then((res) => uploadImages({apartment_ids: res.apartment_ids}))
    } else {
      // update
      updateApartment
        .mutateAsync({ ...req, apartment_id: data?.apartment_id })
        .then((res) => uploadImages({apartment_id: res.apartment_id}))
    }

    // upload images
    function uploadImages({
      apartment_id,
      apartment_ids
    }: {
      apartment_id?: number,
      apartment_ids?: number[]
    }) {
      const formData = new FormData()
      let imageIndex = 1

      images?.forEach((image) => {
        if (!(image as ApartmentImageType).image_id) {
          formData.append(`image_${imageIndex}`, image as RcFile)
          imageIndex++
        }
      })


      apartment_id && formData.append("apartment_id", String(apartment_id))
      apartment_ids && formData.append("apartment_ids", String(apartment_ids))
      imageIndex = 1

      uploadImageApartment.mutate(formData)
    }
  }

  // after close
  const handleAfterClose = () => {
    formInstance.resetFields()
    setData(null)
    dispatch(setRooms([]))
    dispatch(setImages([]))
  }

  // on finish failed
  const onFinishFailed = (
    errorInfo: ValidateErrorEntity<ApartmentFormFields>
  ) => {
    document
      .getElementById(errorInfo.errorFields[0].name[0] as string)
      ?.scrollIntoView({ block: "center", behavior: "smooth" })
  }

  // after success
  function afterSuccess() {
    setVisible(false)
  }

  return (
    <Modal
      open={visible}
      onCancel={handleCancel}
      afterClose={handleAfterClose}
      className={styles.apartments_modal}
      footer={null}
      closable={false}
      centered
    >
      <div className={styles.apartments_modal_header}>
        <p>Xonadon qo’shish</p>
        <CloseIcon onClickFunc={handleCancel} />
      </div>
      <Form
        className={styles.apartments_modal_body}
        layout="vertical"
        form={formInstance}
        onFinish={onFinish}
        autoComplete="off"
        onFinishFailed={onFinishFailed}
      >
        <ApartmentsModalFloor formInstance={formInstance} />
        <ApartmentsModalRoomsCount formInstance={formInstance} />
        <ApartmentsAreaAndPrice formInstance={formInstance} />
        <ApartmentsModalRooms formInstance={formInstance} />
        <ApartmentsModalImages formInstance={formInstance} />
      </Form>
      <div className={styles.apartments_modal_footer}>
        <Button
          onClick={handleSubmit}
          type="primary"
          loading={
            createApartment.isLoading ||
            uploadImageApartment.isLoading ||
            updateApartment.isLoading
          }
        >
          Продолжить
        </Button>
      </div>
    </Modal>
  )
}

export default ApartmentsModal
