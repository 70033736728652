import styles from "./storyChilds.module.scss"
import StoryChildsHeader from "./components/story-childs-header/StoryChildsHeader"
import { useNavigate, useParams } from "react-router-dom"
import { rootPaths } from "../../../../routing/root/rootPaths"
import { storyPaths } from "../../../../routing/story/storyPaths"
import { useDashboardStoryView } from "../../services/queries"
import StoryChildsCard from "./components/story-childs-card/StoryChildsCard"
import PlusIcon from "../../../../assets/icons/PlusIcon"

const StoryChilds = () => {
  const params = useParams()
  const navigate = useNavigate()
  const story_group_id = params?.id

  const { data } = useDashboardStoryView(story_group_id)
  const handleCreate = () => {
    navigate(
      rootPaths.STORY +
        storyPaths.INDEX +
        params?.id +
        "/" +
        params?.name +
        "/new"
    )
  }

  console.log(data)

  return (
    <div className={styles.container}>
      <StoryChildsHeader />
      <div className={styles.content}>
        <div className={styles.createNew} onClick={handleCreate}>
          <PlusIcon /> Yangi yaratish
        </div>
        {data?.map((item) => (
          <StoryChildsCard key={item?.id} {...item} />
        ))}
      </div>
    </div>
  )
}

export default StoryChilds
