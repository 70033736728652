import { useMutation, useQueryClient } from "@tanstack/react-query"

import $api from "../services/RequestService"
import { showMessage } from "../utils/helpers/showMessage"
import {
  apartmentEndpoints,
  blockEndpoints,
  builderEndpoints,
  complexEndpoints,
  magazineEndpoints,
  nearestplacesEndpoints,
} from "../utils/constants/apis"
import { errorHandler } from "../utils/helpers/errorHandler"
import { messageTypes } from "../utils/constants/messageTypes"
import {
  APARTMENT_ID,
  BLOCK_ID,
  BUILDER_ID,
  COMPLEX_ID,
  IMAGE_ID,
  MAGAZINE_ID,
} from "../utils/constants/queryParamsKeys"
import { ErrorRes, SuccessRes } from "../utils/models/responseType"
import { queryKeys } from "../utils/constants/queryKeys"
import { ApartmentReq } from "../utils/models/apartmentModel"
import { BlockReq } from "../utils/models/blockModel"
import { ComplexReq } from "../utils/models/complexModel"
import { StatisticsModel } from "../utils/models/StatisticsModel"
import { NearestplacesModel } from "../utils/models/NearestplacesModel"

// delete article
export function useDeleteMagazine(afterSuccess: () => void) {
  return useMutation<SuccessRes, ErrorRes, { magazineId: string }>(
    async (req) => {
      const res = await $api.delete(
        `${magazineEndpoints.DELETE}?${MAGAZINE_ID}=${req.magazineId}`
      )
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.messages, messageTypes.SUCCESS)
        afterSuccess()
      },
      onError: errorHandler,
    }
  )
}

// create new article
export function useCreateMagazine(afterSuccess: () => void) {
  return useMutation<SuccessRes, ErrorRes, FormData>(
    async (req) => {
      const res = await $api.post(magazineEndpoints.CREATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.messages, messageTypes.SUCCESS)
        afterSuccess()
      },
      onError: errorHandler,
    }
  )
}

// update article
export function useUpdateMagazine(afterSuccess: () => void) {
  return useMutation<SuccessRes, ErrorRes, FormData>(
    async (req) => {
      const res = await $api.post(magazineEndpoints.UPDATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
        afterSuccess()
      },
      onError: errorHandler,
    }
  )
}

// Change status article
export function useChangeStatusArticle() {
  const qc = useQueryClient()
  return useMutation(
    async (id: number) => {
      const res = await $api.get(
        `${magazineEndpoints.STATUS}?${MAGAZINE_ID}=${id}`
      )
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.MAGAZINE])
      },
      onError: errorHandler,
    }
  )
}

// create builder
export function useCreateBuilder(afterSuccess: () => void) {
  return useMutation<SuccessRes, ErrorRes, FormData>(
    async (req) => {
      const res = await $api.post(builderEndpoints.CREATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
        afterSuccess()
      },
      onError: errorHandler,
    }
  )
}

// delete builder
export function useDeleteBuilder(afterSuccess: () => void) {
  return useMutation<SuccessRes, ErrorRes, { builder_id: string }>(
    async (req) => {
      const res = await $api.delete(
        `${builderEndpoints.DELETE}?${BUILDER_ID}=${req.builder_id}`
      )
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
        afterSuccess()
      },
      onError: errorHandler,
    }
  )
}

// update builder
export function useUpdateBuilder(afterSuccess: () => void) {
  return useMutation<SuccessRes, ErrorRes, FormData>(
    async (req) => {
      const res = await $api.post(builderEndpoints.UPDATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
        afterSuccess()
      },
      onError: errorHandler,
    }
  )
}

// create complex
export function useCreateComplex() {
  return useMutation<SuccessRes<{ complex_id: number }>, ErrorRes, ComplexReq>(
    async (req) => {
      const res = await $api.post(complexEndpoints.CREATE, req)
      return res.data
    },
    {
      onError: errorHandler,
    }
  )
}

// update complex
export function useUpdateComplex() {
  return useMutation<SuccessRes, ErrorRes, ComplexReq & { complex_id: string }>(
    async (req) => {
      const res = await $api.put(complexEndpoints.UPDATE, req)
      return res.data
    },
    {
      onError: errorHandler,
    }
  )
}

// upload image complex
export function useUploadImageComplex(afterSuccess: () => void) {
  return useMutation<SuccessRes, ErrorRes, FormData>(
    async (req) => {
      const res = await $api.post(complexEndpoints.IMAGE_UPLOAD, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
        afterSuccess()
      },
      onError: errorHandler,
      retry: 1,
    }
  )
}

// delete image complex
export function useDeleteImageComplex(afterSuccess: () => void) {
  return useMutation<SuccessRes, ErrorRes, number>(
    async (image_id) => {
      const res = await $api.delete(
        `${complexEndpoints.IMAGE_DELETE}?${IMAGE_ID}=${image_id}`
      )
      return res.data
    },
    {
      onSuccess: () => {
        afterSuccess()
      },
      onError: errorHandler,
    }
  )
}

// change complex status
export function useChangeComplexStatus() {
  return useMutation<SuccessRes, ErrorRes, { complex_id: number }>(
    async (req) => {
      const res = await $api.patch(
        `${complexEndpoints.STATUS_CHANGE}?${COMPLEX_ID}=${req.complex_id}`
      )
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
      },
      onError: errorHandler,
    }
  )
}

// delete complex
export function useDeleteComplex(afterSuccess: () => void) {
  return useMutation<SuccessRes, ErrorRes, { complex_id: string }>(
    async (req) => {
      const res = await $api.delete(
        `${complexEndpoints.DELETE}?${COMPLEX_ID}=${req.complex_id}`
      )
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
        afterSuccess()
      },
      onError: errorHandler,
    }
  )
}

// create block
export function useCreateBlock(afterSuccess: () => void) {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, BlockReq>(
    async (req) => {
      const res = await $api.post(blockEndpoints.CREATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.BLOCKS])
        afterSuccess()
      },
      onError: errorHandler,
    }
  )
}

// update block
export function useUpdateBlock(afterSuccess: () => void) {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, BlockReq & { block_id: number }>(
    async (req) => {
      const res = await $api.put(blockEndpoints.UPDATE, req)
      return res.data
    },
    {
      onSuccess: async (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
        await qc.invalidateQueries([queryKeys.BLOCKS])
        afterSuccess()
      },
      onError: errorHandler,
    }
  )
}

// delete block
export function useDeleteBlock(afterSuccess: () => void) {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, number>(
    async (blockId) => {
      const res = await $api.delete(
        `${blockEndpoints.DELETE}?${BLOCK_ID}=${blockId}`
      )
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.BLOCKS])
        afterSuccess()
      },
      onError: errorHandler,
    }
  )
}

// create apartment
export function useCreateApartment() {
  return useMutation<
    SuccessRes<{ apartment_ids: number[] }>,
    ErrorRes,
    ApartmentReq
  >(
    async (req) => {
      const res = await $api.post(apartmentEndpoints.CREATE, req)
      return res.data
    },
    {
      onError: errorHandler,
    }
  )
}

// upload image apartment
export function useUploadImageApartment(afterSuccess: () => void) {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, FormData>(
    async (req) => {
      const res = await $api.post(apartmentEndpoints.IMAGE_UPLOAD, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.APARTMENTS])
        afterSuccess()
      },
      onError: errorHandler,
      retry: 1,
    }
  )
}

// update apartment
export function useUpdateApartment() {
  const qc = useQueryClient()

  return useMutation<
    SuccessRes<{ apartment_id: number }>,
    ErrorRes,
    ApartmentReq & { apartment_id: number }
  >(
    async (req) => {
      const res = await $api.put(apartmentEndpoints.UPDATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.APARTMENTS])
      },
      onError: errorHandler,
    }
  )
}

// delete apartment
export function useDeleteApartment() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, number>(
    async (apartment_id) => {
      const res = await $api.delete(
        `${apartmentEndpoints.DELETE}?${APARTMENT_ID}=${apartment_id}`
      )
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.APARTMENTS])
      },
      onError: errorHandler,
    }
  )
}

// delete image apartment
export function useDeleteImageApartment(afterSuccess: () => void) {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, number>(
    async (image_id) => {
      const res = await $api.delete(
        `${apartmentEndpoints.IMAGE_DELETE}?${IMAGE_ID}=${image_id}`
      )
      return res.data
    },
    {
      onSuccess: () => {
        afterSuccess()
        qc.invalidateQueries([queryKeys.APARTMENTS])
      },
      onError: errorHandler,
    }
  )
}

// get complex statistics
export function useGetComplexStatistics() {
  return useMutation<StatisticsModel[], ErrorRes, number>(
    async (id) => {
      const res = await $api.get(`${complexEndpoints.STATISTICS}${id}`)
      return res.data
    },
    {
      onError: errorHandler,
    },
  )
}

export function useCreatePlacement() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, { complex_id: number, nearest_places: NearestplacesModel[] }>(
    async (data) => {
      const res = await $api.post(nearestplacesEndpoints.CREATE, data)
      return res.data
    },
    {
      onSuccess: (res) => {
        qc.invalidateQueries([queryKeys.COMPLEX_NEARESTPLACES])
        showMessage(res?.messages, messageTypes.SUCCESS)
      },
      onError: errorHandler,
    }
  )
}

export function useDeletePlacement() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, number>(
    async (nearest_place_id) => {
      const res = await $api.delete(nearestplacesEndpoints.DELETE + `?nearest_place_id=${nearest_place_id}`)
      return res.data
    },
    {
      onSuccess: (res) => {
        qc.invalidateQueries([queryKeys.COMPLEX_NEARESTPLACES])
        showMessage(res?.messages, messageTypes.SUCCESS)
      },
      onError: errorHandler,
    }
  )
}