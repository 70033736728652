import { Form, FormInstance, Input, Radio } from "antd"
import React from "react"

import styles from "../ApartmentsModal.module.scss"

type Props = {
  formInstance: FormInstance
}

const { Item } = Form
const { Group, Button: RadioButton } = Radio

const ApartmentsModalRoomsCount: React.FC<Props> = ({ formInstance }) => {
  // rooms
  const rooms = [
    {
      value: 0,
      label: "Студия",
    },
    {
      value: 1,
      label: 1,
    },
    {
      value: 2,
      label: 2,
    },
    {
      value: 3,
      label: 3,
    },
    {
      value: 4,
      label: 4,
    },
    {
      value: 5,
      label: 5,
    },
  ]

  // handle more rooms
  const handleMoreRooms = (
    e: React.ChangeEvent<HTMLInputElement> | undefined
  ) => {
    const room = e?.target.value || ""

    formInstance.setFieldsValue({
      rooms_count: +room,
    })
  }

  // handle rooms
  const handleRooms = () => {
    formInstance.resetFields(["more_rooms"])
  }

  return (
    <>
      <Item
        name="rooms_count"
        label="Xonalar soni"
        className={styles.rooms_count}
        rules={[{ required: true, message: "" }]}
      >
        <Group onChange={handleRooms}>
          {rooms.map((item) => (
            <RadioButton value={item.value} key={item.value}>
              {item.label}
            </RadioButton>
          ))}
        </Group>
      </Item>
      <Item name="more_rooms">
        <Input
          onChange={handleMoreRooms}
          min={1}
          type="number"
          placeholder="6 va undan yuqori bo’lsa buyerga kiriting"
        />
      </Item>
    </>
  )
}

export default ApartmentsModalRoomsCount
