import React from "react"

const PlacementIcon = () => {
  return (
    <svg
      width="13"
      height="22"
      viewBox="0 0 13 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.9 13.4L3.275 20.7C3.225 20.9333 3.104 21.125 2.912 21.275C2.72 21.425 2.49933 21.5 2.25 21.5C1.91667 21.5 1.65 21.375 1.45 21.125C1.25 20.875 1.18333 20.5917 1.25 20.275L3.8 7.4L2 8.1V10.5C2 10.7833 1.904 11.021 1.712 11.213C1.52 11.405 1.28267 11.5007 1 11.5C0.716667 11.5 0.479333 11.404 0.288 11.212C0.0966668 11.02 0.000666667 10.7827 0 10.5V7.45C0 7.25 0.0543334 7.071 0.163 6.913C0.271667 6.755 0.417333 6.634 0.6 6.55L5.05 4.65C5.28333 4.55 5.52933 4.49167 5.788 4.475C6.04667 4.45833 6.29233 4.49167 6.525 4.575C6.75833 4.65833 6.97933 4.775 7.188 4.925C7.39667 5.075 7.56733 5.26667 7.7 5.5L8.7 7.1C8.91667 7.43333 9.171 7.75 9.463 8.05C9.755 8.35 10.084 8.60833 10.45 8.825C10.6833 8.95833 10.9417 9.07933 11.225 9.188C11.5083 9.29667 11.7917 9.37567 12.075 9.425C12.3417 9.475 12.5627 9.596 12.738 9.788C12.9133 9.98 13.0007 10.2173 13 10.5C13 10.7833 12.9 11.0167 12.7 11.2C12.5 11.3833 12.2583 11.4583 11.975 11.425C11.0417 11.2917 10.204 11 9.462 10.55C8.72 10.1 8.07433 9.55833 7.525 8.925L6.9 12L8.7 13.7C8.8 13.8 8.875 13.9127 8.925 14.038C8.975 14.1633 9 14.2923 9 14.425V20.5C9 20.7833 8.904 21.021 8.712 21.213C8.52 21.405 8.28267 21.5007 8 21.5C7.71667 21.5 7.47933 21.404 7.288 21.212C7.09667 21.02 7.00067 20.7827 7 20.5V15L4.9 13.4ZM7.5 4C6.95 4 6.47933 3.80433 6.088 3.413C5.69667 3.02167 5.50067 2.55067 5.5 2C5.5 1.45 5.696 0.979333 6.088 0.588C6.48 0.196667 6.95067 0.000666667 7.5 0C8.05 0 8.521 0.196 8.913 0.588C9.305 0.98 9.50067 1.45067 9.5 2C9.5 2.55 9.30433 3.021 8.913 3.413C8.52167 3.805 8.05067 4.00067 7.5 4Z"
        fill="black"
      />
    </svg>
  )
}

export default PlacementIcon
