import styles from "./story.module.scss"
import { Switch } from "antd"
import EditIcon from "../../assets/icons/EditIcon"
import TrushIcon from "../../assets/icons/TrushIcon"
import { StoryGroupView } from "./utils/models/StoryGroupView"
import { useNavigate } from "react-router-dom"
import { rootPaths } from "../../routing/root/rootPaths"
import { storyPaths } from "../../routing/story/storyPaths"
import { useStoryGroupDelete, useStoryGroupStatus } from "./services/mutation"
import { StoryStatusType } from "./utils/constants/StoryStatusType"
import { LoadingOutlined } from "@ant-design/icons"

const HistoryAction = (props: StoryGroupView) => {
  const navigate = useNavigate()
  const storyGroupStatusMutate = useStoryGroupStatus()
  const storyGroupDeleteMutate = useStoryGroupDelete()

  const changeStatus = (checked: boolean) => {
    storyGroupStatusMutate.mutate({
      id: props?.id,
      status: checked ? StoryStatusType.ACTIVE : StoryStatusType.PASSIVE,
    })
  }

  const handleDelete = () => {
    storyGroupDeleteMutate.mutate(props?.id)
  }

  return (
    <div className={styles.action} onClick={(e) => e.stopPropagation()}>
      <Switch
        checked={props?.status === StoryStatusType.ACTIVE ? true : false}
        onChange={changeStatus}
        loading={storyGroupStatusMutate.isLoading}
      />
      <EditIcon
        onClick={() => {
          navigate(rootPaths.STORY + storyPaths.INDEX + props?.id)
        }}
      />
      {storyGroupDeleteMutate.isLoading ? (
        <LoadingOutlined style={{ fontSize: 20 }} />
      ) : (
        <TrushIcon onClick={handleDelete} />
      )}
    </div>
  )
}

export default HistoryAction
