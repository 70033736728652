import React from "react"

type Props = {
  onClickFunc?: () => void
  className?: string
}

const BlockUpdateIcon: React.FC<Props> = ({ onClickFunc, className }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClickFunc}
      className={className}
    >
      <path
        d="M8.84006 2.90031L3.36673 8.69364C3.16006 8.91364 2.96006 9.34697 2.92006 9.64697L2.6734 11.807C2.58673 12.587 3.14673 13.1203 3.92006 12.987L6.06673 12.6203C6.36673 12.567 6.78673 12.347 6.9934 12.1203L12.4667 6.32697C13.4134 5.32697 13.8401 4.18697 12.3667 2.79364C10.9001 1.41364 9.78673 1.90031 8.84006 2.90031Z"
        stroke="#2c2c2c"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.92676 3.8667C8.21342 5.7067 9.70676 7.11337 11.5601 7.30003"
        stroke="#2c2c2c"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 15.1665H14"
        stroke="#2c2c2c"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BlockUpdateIcon
