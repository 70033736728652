import { Button, Switch } from "antd"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import UpdateIcon from "../../assets/icons/UpdateIcon"

import { useChangeComplexStatus, useGetComplexStatistics } from "../../queries/mutations"
import { complexesPaths } from "../../routing/complexes/complexesPaths"
import { rootPaths } from "../../routing/root/rootPaths"
import { statuses } from "../../utils/constants/statuses"
import { ComplexRes } from "../../utils/models/complexModel"

import styles from "./ComplexCard.module.scss"
import StatisticsIcon from "../../assets/icons/StatisticsIcon"
import Statistics from "../../components/complexes/statistics/Statistics"

const ComplexCard: React.FC<ComplexRes> = ({
                                             address,
                                             apartments_count,
                                             class_name,
                                             complex_id,
                                             deadline,
                                             name,
                                             status,
                                             statistics,
                                           }) => {
  const navigate = useNavigate()
  const [isActive, setIsActive] = useState(
    statuses[status as keyof typeof statuses],
  )
  const changeComplexStatusMutation = useChangeComplexStatus()
  const [visibleStatistics, setVisibleStatistics] = useState<boolean>(false);
  const changeStatistics = useGetComplexStatistics();

  // handle change status
  const handleChangeStatus = (
    _: unknown,
    e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    e.stopPropagation()
    changeComplexStatusMutation
      .mutateAsync({ complex_id })
      .then(() => setIsActive((prev) => !prev))
  }

  // to detailed complex
  const toDetailedComplex = (
    e: React.MouseEvent<SVGSVGElement> | undefined,
  ) => {
    e?.stopPropagation()
    navigate(`${rootPaths.RESIDENTAL_COMPLEXES}/${complex_id}`)
  }

  // to blocks
  const toBlocks = (e: React.MouseEvent<HTMLElement> | undefined) => {
    e?.stopPropagation()
    navigate(
      `${
        rootPaths.RESIDENTAL_COMPLEXES + complexesPaths.BLOCKS_LINK
      }/${complex_id}`,
    )
  }

  // to statistics
  const toStatistics = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setVisibleStatistics(true);
    changeStatistics.mutate(complex_id)
  }

  return (
    <>
      <div className={styles.complex_card} onClick={toBlocks}>
        <div className={styles.header}>
          <h2>{name}</h2>
          <UpdateIcon onClickFunc={toDetailedComplex} />
        </div>
        <p className={styles.address}>{address}</p>
        <hr />
        <div className={styles.more}>
          <p>Topshirish vaqti</p>
          <span>{deadline}</span>
        </div>
        <div className={styles.more}>
          <p>Uylar soni</p>
          <span>{apartments_count}</span>
        </div>
        <div className={styles.more}>
          <p>TJM sinfi</p>
          <span>{class_name}</span>
        </div>
        <div className={styles.more}>
          <p>Saytda ko’rsatish</p>
          <Switch
            checked={isActive}
            onChange={handleChangeStatus}
            loading={changeComplexStatusMutation.isLoading}
          />
        </div>
        <div className={styles.statistics}>
          {
            statistics.map((item, index) => (
              <div className={styles.item} key={index}>
                <img src={item.icon} alt={item.icon} />
                <span>{item.count}</span>
              </div>
            ))
          }
          <div className={styles.item}>
            <Button onClick={toStatistics}>
              <StatisticsIcon />
            </Button>
          </div>
        </div>
      </div>
      <Statistics data={changeStatistics.data} default_statistics={statistics} visible={visibleStatistics} setVisible={setVisibleStatistics}
                  complex_id={complex_id} />
    </>
  )
}

export default ComplexCard
