import { Form, Select } from "antd"
import React from "react"

import discountCardImg from "../../../../assets/images/discountsCard.png"
import { useGetPaymentMethods } from "../../../../queries/queries"

import styles from "../DetailedComplex.module.scss"

const { Item } = Form
const { Option } = Select

const DetailedComlpexDiscounts: React.FC = () => {
  const { data: paymentMethods } = useGetPaymentMethods()

  return (
    <div className={styles.discounts}>
      <p className={styles.little_title}>Bonus va aksiyalar</p>
      <div className={styles.discounts_body}>
        <div className={styles.discounts_body_left}>
          <Item name="bonuses" label="Aksiya">
            <Select allowClear>
              <Option value="Скидка до 3%">Скидка до 3%</Option>
              <Option value="Скидка до 5%">Скидка до 5%</Option>
              <Option value="Скидка до 10%">Скидка до 10%</Option>
              <Option value="Скидка до 15%">Скидка до 15%</Option>
              <Option value="Скидка до 20%">Скидка до 20%</Option>
            </Select>
          </Item>
          <Item name="payment_method_ids" label="To’lov usullari">
            <Select mode="multiple">
              {paymentMethods?.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name.uz}
                </Option>
              ))}
            </Select>
          </Item>
        </div>
        <div className={styles.discounts_body_right}>
          <img src={discountCardImg} alt="discount-card" />
        </div>
      </div>
    </div>
  )
}

export default DetailedComlpexDiscounts
