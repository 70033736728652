import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MainLayout from '../../layouts/mainLayout/MainLayout'

import { exactRouteFixer } from '../../utils/helpers/exactRouteFixer'
import RequirePermission from '../RequirePermission'
import { maagazinesRoutes } from './magazinesRoutes'

const MagazinesRouter: React.FC = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        {maagazinesRoutes.map((item) => (
          <Route
            element={<RequirePermission permission={item.neededPermissions} />}
            key={item.path}
          >
            <Route
              path={exactRouteFixer(item.path, item.isExact)}
              element={<item.element />}
            />
          </Route>
        ))}
      </Route>
    </Routes>
  )
}

export default MagazinesRouter
