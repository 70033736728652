import { Button, Form, FormInstance, Upload } from "antd"
import { UploadChangeParam, UploadFile } from "antd/lib/upload"
import React, { useEffect } from "react"

import UploadImageIcon from "../../../../assets/icons/UploadImageIcon"
import ImageCont from "./ImageCont"
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks"
import { apartmentReducerActions } from "../../../../store/reducers/apartmentReducer"

import styles from "../ApartmentsModal.module.scss"

type Props = {
  formInstance: FormInstance
}

const { Dragger } = Upload
const { Item } = Form

const ApartmentsModalImages: React.FC<Props> = ({ formInstance }) => {
  const dispatch = useAppDispatch()
  const { addImage } = apartmentReducerActions
  const { images } = useAppSelector((state) => state.apartmentReducer)

  // set images
  useEffect(() => {
    formInstance.setFieldsValue({
      images: images,
    })
  }, [formInstance, images])

  // handle drop change
  const handleDropChange = (e: UploadChangeParam<UploadFile>) => {
    const newImage = e.file.originFileObj

    newImage && dispatch(addImage(newImage))
  }

  return (
    <div className={styles.images}>
      <p className={styles.images_title}>Xonadon fotosuratlari</p>
      <div className={styles.images_body}>
        <Item
          name="images"
          rules={[
            () => ({
              validator(_, value) {
                if (value.length === 0) return Promise.reject()
                return Promise.resolve()
              },
            }),
          ]}
        >
          <Dragger
            className={styles.dragger}
            showUploadList={false}
            onChange={handleDropChange}
            customRequest={() => null}
            accept=".jpg, .jpeg, .png"
          >
            <UploadImageIcon />
            <p className={styles.description}>Drag and drop photos here</p>
            <p className={styles.accept}>
              Yuborish mumkin fayllar: PNG, JPEG, JPG
            </p>
            <Button>Fayl tanlash</Button>
            <p className={styles.max_size}>Maksimal hajm: 10 Mb</p>
          </Dragger>
        </Item>
        <div className={styles.images_cont}>
          {images?.map((item, index) => (
            <ImageCont key={index} data={item} index={index} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ApartmentsModalImages
