import React from "react"
import { Button, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import { parse } from "query-string"
import { useLocation, useNavigate } from "react-router-dom"

import ArrowLeftIcon from "../../../assets/icons/ArrowLeftIcon"
import PlusIcon from "../../../assets/icons/PlusIcon"
import CustomPagination from "../../../common/customPagination/CustomPagination"
import MagazinesTableSwitch from "./MagazinesTableSwitch"
import styles from "./MagazinesTable.module.scss"

import { useGetMagazine } from "../../../queries/queries"
import { magazinesPaths } from "../../../routing/magazines/magazinesPaths"
import { rootPaths } from "../../../routing/root/rootPaths"
import { tableNumber } from "../../../utils/helpers/tableNumber"
import { MagazineType } from "../../../utils/models/magazineType"

const Magazines: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const page = parse(location.search).page

  // all article
  const { data, isLoading } = useGetMagazine(page as string)

  // columns
  const columns: ColumnsType<MagazineType> = [
    {
      title: "№",
      render: (_, __, index: number) => tableNumber(index, page as string),
      width: 60,
    },
    {
      title: "Maqola nomi",
      dataIndex: "name",
    },
    {
      title: "Sana",
      dataIndex: "created_time",
    },
    {
      title: "Status",
      render: (item: MagazineType) => (
        <MagazinesTableSwitch
          article_id={item.article_id}
          status={item.status}
          key={item.article_id}
        />
      ),
    },
    {
      title: "",
      render: () => (
        <div className={styles.right_icon}>
          <ArrowLeftIcon />
        </div>
      ),
    },
  ]

  // to detailed magazine
  const toDetailedMagazine = (magazineId: number) => {
    navigate(`${rootPaths.MAGAZINES}/${magazineId}`)
  }

  // to articles page
  const toNewMagazine = () => {
    navigate(`${rootPaths.MAGAZINES}${magazinesPaths.NEW_MAGAZINES}`)
  }

  return (
    <div className={styles.container}>
      <div className={styles.container_title}>
        <h1>Jurnal</h1>
        <Button onClick={toNewMagazine}>
          <PlusIcon />
          <span>Yangi yaratish</span>
        </Button>
      </div>
      <div className={styles.container_body}>
        <Table
          className={styles.magezines_table}
          dataSource={data?.data}
          columns={columns}
          pagination={false}
          loading={isLoading}
          onRow={(record) => {
            return {
              onClick: () => toDetailedMagazine(record.article_id),
            }
          }}
          rowKey={(record: MagazineType) => record.article_id}
        />
        <CustomPagination total={data?.total || 1} />
      </div>
    </div>
  )
}

export default Magazines
