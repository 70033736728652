import React from "react"
import { Checkbox, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { CheckboxChangeEvent } from "antd/lib/checkbox"

import { useDeleteApartment, useUpdateApartment } from "../../queries/mutations"
import { noImage } from "../../utils/constants/noImage"
import {
  ApartmentPermissions,
  ApartmentType,
} from "../../utils/models/apartmentModel"
import BlockTrashIcon from "../../assets/icons/BlockTrashIcon"
import BlockUpdateIcon from "../../assets/icons/BlockUpdateIcon"
import { useAppDispatch } from "../../hooks/reduxHooks"
import { apartmentReducerActions } from "../../store/reducers/apartmentReducer"
import { isEmptyArr } from "../../utils/helpers/isEmptyArr"

import styles from "./ApartmentCard.module.scss"
import Popconfirm from "./popconfirm/Popconfirm"

type Props = {
  data: ApartmentType
  setVisibleModal: (visible: boolean) => void
  setModalData: React.Dispatch<React.SetStateAction<ApartmentType | null>>
}

const ApartmentCard: React.FC<Props> = ({
  data,
  setVisibleModal,
  setModalData,
}) => {
  const dispatch = useAppDispatch()
  const { setRooms, setImages } = apartmentReducerActions
  const updatePricePermission = useUpdateApartment()
  const updateTotalPricePermission = useUpdateApartment()
  const deleteApartment = useDeleteApartment()
  const { pricePermission, totalPricePermission } = ApartmentPermissions

  // handle change price permission
  const handleChangePricePermission = (
    e: CheckboxChangeEvent,
    fieldName: typeof pricePermission | typeof totalPricePermission
  ) => {
    // delete unnecessary fields
    delete data?.images

    switch (fieldName) {
      case pricePermission:
        updatePricePermission.mutate({
          ...data,
          price_permission: Number(e?.target?.checked),
        })
        return
      case totalPricePermission:
        updateTotalPricePermission.mutate({
          ...data,
          total_price_permission: Number(e?.target?.checked),
        })
        return
    }
  }

  // handle open modal
  const handleOpenModal = () => {
    setModalData(data)
    dispatch(setRooms(data?.rooms))
    dispatch(setImages(data?.images!))
    setVisibleModal(true)
  }

  // image source
  const imageSource = () => {
    if (data?.images && !isEmptyArr(data?.images)) return data?.images[0]?.small
    return noImage
  }

  // handle delete
  const handleDelete = () => {
    deleteApartment.mutate(data?.apartment_id)
  }

  return (
    <div className={styles.container}>
      <div className={styles.apartment_card}>
        <img className={styles.image} src={imageSource()} alt="apartment" />
        <div className={styles.area_cont}>
          <p>{data.rooms_count} xonali</p>
          <span>
            {data.area} m<sup>2</sup>
          </span>
        </div>
        <div>
          <p>{data.floor} qavatlarda</p>
          <span></span>
        </div>
        <div className={styles.price_cont}>
          <label className={styles.total_price}>
            <span>{data?.total_price?.toLocaleString()}&nbsp;so'm</span>
            {updateTotalPricePermission.isLoading ? (
              <Spin indicator={<LoadingOutlined size={10} spin />} />
            ) : (
              <Checkbox
                checked={!!data.total_price_permission}
                onChange={(e) =>
                  handleChangePricePermission(e, totalPricePermission)
                }
              />
            )}
          </label>
          <label className={styles.price}>
            <span>
              {data?.price?.toLocaleString()}&nbsp;so'm/m<sup>2</sup>
            </span>
            {updatePricePermission.isLoading ? (
              <Spin indicator={<LoadingOutlined size={10} spin />} />
            ) : (
              <Checkbox
                checked={!!data?.price_permission}
                onChange={(e) =>
                  handleChangePricePermission(e, pricePermission)
                }
              />
            )}
          </label>
        </div>
      </div>
      <div className={styles.actions}>
        <BlockUpdateIcon
          onClickFunc={handleOpenModal}
          className={styles.actions_icon}
        />
        <Popconfirm
          isLoading={deleteApartment.isLoading}
          onConfirm={handleDelete}
        >
          <BlockTrashIcon className={styles.actions_icon} />
        </Popconfirm>
      </div>
    </div>
  )
}

export default ApartmentCard
