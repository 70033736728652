import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { exactRouteFixer } from '../../utils/helpers/exactRouteFixer'
import RequirePermission from '../RequirePermission'
import { rootRoutes } from './rootRoutes'

const RootRouter: React.FC = () => {
  return (
    <Routes>
      {rootRoutes.map((item) => {
        return (
          <Route
            element={<RequirePermission permission={item.neededPermissions} />}
            key={item.path}
          >
            <Route
              path={exactRouteFixer(item.path, item.isExact)}
              element={<item.element />}
            />
          </Route>
        )
      })}
    </Routes>
  )
}

export default RootRouter
