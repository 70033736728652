import { Button, Form, Popconfirm, Spin } from "antd"
import { RcFile } from "antd/lib/upload"
import { ValidateErrorEntity } from "rc-field-form/es/interface"
import React, { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import ArrowLeftIcon from "../../../assets/icons/ArrowLeftIcon"
import TrashIcon from "../../../assets/icons/TrashIcon"
import {
  useCreateComplex,
  useDeleteComplex,
  useUpdateComplex,
  useUploadImageComplex,
} from "../../../queries/mutations"
import { useGetOneComplex } from "../../../queries/queries"
import { phoneNumberFixer } from "../../../utils/helpers/phoneNumberFixer"
import {
  ComplexFormFields,
  ComplexImageType,
} from "../../../utils/models/complexModel"
import DetailedComplexAbout from "./about/DetailedComplexAbout"
import DetailedComlpexBasicInformation from "./basicInformation/DetailedComlpexBasicInformation"
import DetailedComplexContact from "./contact/DetailedComplexContact"
import DetailedComlpexDiscounts from "./discounts/DetailedComlpexDiscounts"
import DetailedComplexFeatures from "./features/DetailedComplexFeatures"
import DetailedComplexImages from "./images/DetailedComplexImages"
import DetailedComplexMetaKeywords from "./metaKeywords/DetailedComplexMetaKeywords"

import styles from "./DetailedComplex.module.scss"
import PlacementIcon from "../../../assets/icons/PlacementIcon"
import PlacementsModal from "./placements/PlacementsModal"

const DetailedComplex: React.FC = () => {
  const params = useParams<{ id: string }>()
  const [formInstance] = Form.useForm()
  const navigate = useNavigate()
  const { data, isLoading } = useGetOneComplex(Number(params.id))
  const createComplex = useCreateComplex()
  const updateComplex = useUpdateComplex()
  const deleteComplex = useDeleteComplex(goBack)
  const uploadImageComplex = useUploadImageComplex(goBack)

  const [openModal, setOpenModal] = useState(false)

  // is updating
  const isUpdating = useMemo(() => !!Number(params.id), [params.id])

  // initial fields
  useEffect(() => {
    if (data) {
      formInstance.setFieldsValue({
        ...data,
        payment_method_ids: data.payment_methods?.map(
          (item) => item.payment_method_id
        ),
        key_words: data.key_words || [],
        bonuses: data.bonuses || "",
      })
    }
  }, [data, formInstance])

  // go back
  function goBack() {
    navigate(-1)
  }

  // on finish
  const onFinish = async (fields: ComplexFormFields) => {
    const images = fields?.images?.slice() ?? []

    // delete unnecessary field
    delete fields?.add_feature
    delete fields?.images

    // req
    const req = {
      ...fields,
      phone: phoneNumberFixer(fields?.phone),
      address_map: fields?.address_map?.join(),
      bonuses: fields?.bonuses,
      specifices: fields?.specifices?.join(),
      payment_method_ids: fields?.payment_method_ids ?? [],
    }

    if (isUpdating) {
      // update
      updateComplex
        .mutateAsync({ ...req, complex_id: params.id! })
        .then(() => uploadImages(params.id!))
    } else {
      // create
      createComplex
        .mutateAsync(req)
        .then((res) => uploadImages(String(res.complex_id)))
    }

    // upload images
    function uploadImages(complex_id: string) {
      const formData = new FormData()
      let imageIndex = 1

      images?.forEach((image) => {
        if (!(image as ComplexImageType).image_id) {
          formData.append(`image_${imageIndex}`, image as RcFile)
          imageIndex++
        }
      })

      formData.append("complex_id", String(complex_id))
      imageIndex = 1

      uploadImageComplex.mutate(formData)
    }
  }

  // on finish failed
  const onFinishFailed = (
    errorInfo: ValidateErrorEntity<ComplexFormFields>
  ) => {
    document
      .getElementById(errorInfo.errorFields[0].name[0] as string)
      ?.scrollIntoView({ block: "center", behavior: "smooth" })
  }

  // handle delete
  const handleDelete = () => {
    deleteComplex.mutate({ complex_id: params.id! })
  }

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  return (
    <div className={styles.container}>
      <div className={styles.container_header}>
        <Button className={styles.back} onClick={goBack}>
          <ArrowLeftIcon />
          <span>“Turar-joy majmualari” ga qaytish</span>
        </Button>
        {isUpdating && (
          <div className={styles.container_header_btns}>
            <Button onClick={handleOpenModal}>
              <PlacementIcon />
              <span>Joylar</span>
            </Button>
            <Popconfirm
              title="O'chirmoqchimisiz?"
              onConfirm={handleDelete}
              okText="Ha"
              cancelText="Yo'q"
            >
              <Button
                className={styles.delete}
                loading={deleteComplex.isLoading}
              >
                <TrashIcon />
                <span>O’chirish</span>
              </Button>
            </Popconfirm>
          </div>
        )}
      </div>
      <Spin spinning={isUpdating && isLoading}>
        <div className={styles.container_body}>
          <Form
            layout="vertical"
            form={formInstance}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <DetailedComlpexBasicInformation
              formInstance={formInstance}
              coords={data?.address_map}
              city_id={data?.city_id}
            />
            <DetailedComplexImages
              formInstance={formInstance}
              data={data?.images}
            />
            <DetailedComlpexDiscounts />
            <DetailedComplexAbout />
            <DetailedComplexFeatures
              formInstance={formInstance}
              data={data?.specifices}
            />
            <DetailedComplexContact />
            <DetailedComplexMetaKeywords formInstance={formInstance} />
            {/* <DetailedComplexApartments /> */}
            <Button
              htmlType="submit"
              type="primary"
              className={styles.submit_button}
              loading={
                createComplex.isLoading ||
                updateComplex.isLoading ||
                uploadImageComplex.isLoading
              }
            >
              Saqlash
            </Button>
          </Form>
        </div>
      </Spin>
      <PlacementsModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        regionId={Number(params?.id)}
      />
    </div>
  )
}

export default DetailedComplex
