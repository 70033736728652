import { Checkbox, Form, FormInstance, Input } from "antd"
import React from "react"

import { localeFormatter } from "../../../../utils/helpers/localeFormatter"

import styles from "../ApartmentsModal.module.scss"
import { parseLocaledString } from "../../../../utils/helpers/parseLocaledString"

type Props = {
  formInstance: FormInstance
}

const { Item } = Form

const ApartmentsAreaAndPrice: React.FC<Props> = ({ formInstance }) => {
  // handle price fields
  const handlePriceFields = (field: string) => {
    const value = formInstance.getFieldValue([field])
    const area = parseLocaledString(String(formInstance.getFieldValue("area")))
    const valueNumber = parseLocaledString(value)

    const obj: Record<string, {}> = {
      price: {
        total_price: (valueNumber * area).toLocaleString("ru"),
      },
      total_price: {
        price: (valueNumber / area).toLocaleString("ru"),
      },
    }

    formInstance.setFieldsValue({
      ...(area && obj[field]),
      [field]: localeFormatter(value),
    })
  }

  return (
    <>
      <Item
        label="Maydoni"
        name="area"
        rules={[{ required: true, message: "" }]}
      >
        <Input suffix="m²" type="number" min={1} />
      </Item>
      <div className={styles.label_with_checkbox}>
        <label htmlFor="total_price">
          1 m<sup>2</sup> uchun narx
        </label>
        <Item
          name="price_permission"
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox>saytga chiqarish</Checkbox>
        </Item>
      </div>
      <Item name="price" rules={[{ required: true, message: "" }]}>
        <Input suffix="so'm" onChange={() => handlePriceFields("price")} />
      </Item>
      <div className={styles.label_with_checkbox}>
        <label htmlFor="total_price">Umumiy narx</label>
        <Item
          name="total_price_permission"
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox>saytga chiqarish</Checkbox>
        </Item>
      </div>
      <Item name="total_price" rules={[{ required: true, message: "" }]}>
        <Input
          suffix="so'm"
          onChange={() => handlePriceFields("total_price")}
        />
      </Item>
    </>
  )
}

export default ApartmentsAreaAndPrice
