import React from 'react'

const MagazinesIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.93 2.5V8.4C14.93 8.84 14.41 9.06 14.09 8.77L12.34 7.16C12.15 6.98 11.85 6.98 11.66 7.16L9.91001 8.76C9.59001 9.06 9.07001 8.83 9.07001 8.4V2.5C9.07001 2.22 9.29001 2 9.57001 2H14.43C14.71 2 14.93 2.22 14.93 2.5Z"
        fill="#616161"
      />
      <path
        d="M16.98 2.06001C16.69 2.02001 16.43 2.27001 16.43 2.56001V8.58001C16.43 9.34001 15.98 10.03 15.28 10.34C14.58 10.64 13.77 10.51 13.21 9.99001L12.34 9.19001C12.15 9.01001 11.86 9.01001 11.66 9.19001L10.79 9.99001C10.43 10.33 9.96 10.5 9.49 10.5C9.23 10.5 8.97 10.45 8.72 10.34C8.02 10.03 7.57 9.34001 7.57 8.58001V2.56001C7.57 2.27001 7.31 2.02001 7.02 2.06001C4.22 2.41001 3 4.30001 3 7.00001V17C3 20 4.5 22 8 22H16C19.5 22 21 20 21 17V7.00001C21 4.30001 19.78 2.41001 16.98 2.06001ZM17.5 18.75H9C8.59 18.75 8.25 18.41 8.25 18C8.25 17.59 8.59 17.25 9 17.25H17.5C17.91 17.25 18.25 17.59 18.25 18C18.25 18.41 17.91 18.75 17.5 18.75ZM17.5 14.75H13.25C12.84 14.75 12.5 14.41 12.5 14C12.5 13.59 12.84 13.25 13.25 13.25H17.5C17.91 13.25 18.25 13.59 18.25 14C18.25 14.41 17.91 14.75 17.5 14.75Z"
        fill="#616161"
      />
    </svg>
  )
}

export default MagazinesIcon
