import { useNavigate, useParams } from "react-router-dom"
import styles from "./storyChildId.module.scss"
import { Button, Form, Input } from "antd"
import ArrowLeftIcon from "../../../../../../assets/icons/ArrowLeftIcon"
import TrushBoldIcon from "../../../../../../assets/icons/TrushBoldIcon"
import ImageUpload from "../../../../../../common/image-upload/ImageUpload"
import { useStoryCreate } from "../../../../services/mutation"

const StoryChildId = () => {
  const params = useParams()
  const navigate = useNavigate()
  const storyCreateMutate = useStoryCreate()

  const story_group_id = params?.id

  const onFinish = (values: any) => {
    const formData = new FormData()

    const fields = {
      story_group_id,
      ...values,
    }

    if (typeof fields.image === "string") delete fields.image
    if (!fields.url) delete fields.url

    Object.keys(fields).map((key) => {
      formData.append(key, fields[key])
    })

    storyCreateMutate.mutateAsync(formData).then(() => {
      navigate(-1)
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Button onClick={() => navigate(-1)}>
          <ArrowLeftIcon /> "{params.name}" ga qaytish
        </Button>
        {/* <Button>
          <TrushBoldIcon /> O’chirish
        </Button> */}
      </div>
      <Form className={styles.form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Intro"
          name={"image"}
          rules={[{ required: true, message: "" }]}
        >
          <ImageUpload accept=".jpg, .jpeg, .png, .mp4, .mov" />
        </Form.Item>
        {/* <Form.Item label="Target" name={"target"}>
          <Input />
        </Form.Item> */}
        <Form.Item
          label="Button URL"
          name={"url"}
          // rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          loading={storyCreateMutate.isLoading}
        >
          Saqlash
        </Button>
      </Form>
    </div>
  )
}

export default StoryChildId
