import React from "react"
import { Form, FormInstance, Input, Select } from "antd"

import { isEmptyArr } from "../../../../utils/helpers/isEmptyArr"

import styles from "../DetailedComplex.module.scss"

const { Item } = Form
const { TextArea } = Input

type Props = {
  formInstance: FormInstance
}

const DetailedComplexMetaKeywords: React.FC<Props> = ({ formInstance }) => {
  // change
  const handleChange = (value: string[]) => {
    if (isEmptyArr(value)) {
      formInstance.setFieldsValue({
        key_words: value,
      })
      return
    }

    let newValue: string[] = []

    value?.forEach((item) => {
      newValue = [...newValue, ...item?.split(",")]
    })

    formInstance.setFieldsValue({
      key_words: newValue,
    })
  }

  return (
    <div className={styles.keywords}>
      <p className={styles.little_title}>SEO</p>
      <Item label="Kalit so’zlar" name="key_words">
        <Select mode="tags" open={false} onChange={handleChange} />
      </Item>
      <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <Item name="meta_tags" label="Meta taglar">
          <Input />
        </Item>
        <Item name="meta_description" label="Meta description">
          <TextArea />
        </Item>
      </div>
    </div>
  )
}

export default DetailedComplexMetaKeywords
