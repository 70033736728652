import DetailedMagazine from "../../components/magazines/detailed/DetailedMagazine"
import Magazines from "../../components/magazines/table/MagazinesTable"
import { RouteType } from "../../utils/models/routeModel"
import { magazinesPaths } from "./magazinesPaths"

export const maagazinesRoutes: RouteType[] = [
  {
    path: magazinesPaths.INDEX,
    element: Magazines,
    isExact: true,
  },
  {
    path: magazinesPaths.DETAILED,
    element: DetailedMagazine,
    isExact: true,
  },
  {
    path: magazinesPaths.NEW_MAGAZINES,
    element: DetailedMagazine,
    isExact: true,
  },
]
