import React from "react"
import { Route, Routes } from "react-router-dom"
import MainLayout from "../../layouts/mainLayout/MainLayout"
import { storyRoutes } from "./storyRoutes"
import RequirePermission from "../RequirePermission"
import { exactRouteFixer } from "../../utils/helpers/exactRouteFixer"

const HistoryRouter = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        {storyRoutes.map((item) => (
          <Route
            element={<RequirePermission permission={item.neededPermissions} />}
            key={item.path}
          >
            <Route
              path={exactRouteFixer(item.path, item.isExact)}
              element={<item.element />}
            />
          </Route>
        ))}
      </Route>
    </Routes>
  )
}

export default HistoryRouter
