import React, { useState, useMemo } from "react"
import { Image, Spin } from "antd"

import EyeIcon from "../../../../assets/icons/EyeIcon"
import PreviewTrashIcon from "../../../../assets/icons/PreviewTrashIcon"

import styles from "../DetailedComplex.module.scss"
import { RcFile } from "antd/lib/upload"
import { ComplexImageType } from "../../../../utils/models/complexModel"
import { useDeleteImageComplex } from "../../../../queries/mutations"

type Props = {
  setImages: React.Dispatch<
    React.SetStateAction<Array<RcFile | ComplexImageType>>
  >
  data: RcFile | ComplexImageType
  index: number
}

const ImageCont: React.FC<Props> = ({ setImages, data, index }) => {
  const [visiblePreview, setVisiblePreview] = useState(false)
  const deleteImageComplex = useDeleteImageComplex(afterSuccessDelete)

  // open preview
  const handleOpenPreview = () => {
    setVisiblePreview(true)
  }

  // is image file
  const isImageFile = useMemo(
    () => !(data as ComplexImageType).image_id,
    [data]
  )

  // hondle delete
  function handleDelete() {
    if (isImageFile) {
      setImages((prev) => prev?.filter((_, i) => i !== index))
    } else {
      deleteImageComplex.mutate((data as ComplexImageType).image_id)
    }
  }

  // image source
  const imageSource = () => {
    if (!isImageFile) return (data as ComplexImageType)?.small as string
    return URL.createObjectURL(data as RcFile)
  }

  // image preview source
  const imagePreviewSource = () => {
    if (!isImageFile) return (data as ComplexImageType)?.large as string
    return URL.createObjectURL(data as RcFile)
  }

  // after success delete
  function afterSuccessDelete() {
    setImages((prev) => prev?.filter((_, i) => i !== index))
  }

  return (
    <div className={styles.image}>
      <Spin spinning={deleteImageComplex.isLoading}>
        <Image
          preview={{
            visible: visiblePreview,
            mask: (
              <div className={styles.mask_cont}>
                <EyeIcon onClickFunc={handleOpenPreview} />
                <PreviewTrashIcon onClickFunc={handleDelete} />
              </div>
            ),
            onVisibleChange: (value) => {
              !value && setVisiblePreview(value)
            },
            src: imagePreviewSource(),
          }}
          src={imageSource()}
          alt="apartment"
        />
      </Spin>
    </div>
  )
}

export default React.memo(ImageCont)
