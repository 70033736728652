import { Form, FormInstance } from "antd"
import L, { CRS, LatLngTuple } from "leaflet"
import React, { useState, useEffect } from "react"
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet"

import "leaflet/dist/leaflet.css"
import styles from "../DetailedComplex.module.scss"

type Props = {
  formInstance: FormInstance
  coords: number[] | undefined
}

const { Item } = Form

const MapUpdate: React.FC<Props> = ({ formInstance, coords }) => {
  const [position, setPosition] = useState(coords)

  useEffect(() => {
    if (coords) {
      setPosition(coords)
    }
  }, [coords])

  // location marker
  function LocationMarker() {
    const icon = new L.Icon({
      iconSize: new L.Point(12, 12),
      iconUrl: "/markIcon.svg",
    })

    useMapEvents({
      click(e) {
        setPosition(Object.values(e?.latlng))
        formInstance.setFieldsValue({
          address_map: Object.values(e?.latlng),
        })
      },
    })

    return position ? (
      <Marker position={position as LatLngTuple} icon={icon} />
    ) : null
  }

  return (
    <Item
      className={styles.fourth_row}
      name="address_map"
      rules={[{ required: true, message: "" }]}
    >
      <div className={styles.map}>
        <span id="address_map" />

        <MapContainer
          className={styles.map_container}
          center={(position as LatLngTuple) || [40.008544, 65.36646]}
          zoom={10}
          scrollWheelZoom={true}
          crs={CRS.EPSG3395}
        >
          <TileLayer
            attribution=""
            url="https://core-renderer-tiles.maps.yandex.net/tiles?l=map&x={x}&y={y}&z={z}&scale=1&lang=ru_RU'"
          />
          <LocationMarker />
        </MapContainer>
      </div>
    </Item>
  )
}

export default MapUpdate
