import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "../utils/constants/queryKeys"
import { $api2 } from "../../../services/RequestService"
import { endPoints } from "../utils/constants/endPoints"
import { Pagination } from "../../../utils/models/Pagination"
import { StoryGroupView } from "../utils/models/StoryGroupView"
import { StoryView } from "../utils/models/StoryView"

export function useDashboardStoryGroupView(
  page: string | number,
  search: string
) {
  return useQuery<Pagination<StoryGroupView>>({
    queryKey: [queryKeys.DASHBOARD_STORY_GROUP_VIEW, page, search],
    queryFn: async () => {
      const res = await $api2.get(
        endPoints.DASHBOARD_STORY_GROUP_VIEW +
          `?page=${page}&size=10&search=${search}`
      )
      return res.data
    },
  })
}

export function useDashboardStoryGroupViewId(id?: string | number) {
  return useQuery<StoryGroupView>({
    queryKey: [queryKeys.DASHBOARD_STORY_GROUP_VIEW_ID, id],
    queryFn: async () => {
      const res = await $api2.get(
        endPoints.DASHBOARD_STORY_GROUP_VIEW + `?id=${id}`
      )
      return res.data.data
    },
    enabled: !!id,
  })
}

export function useDashboardStoryView(
  story_group_id: number | string | undefined
) {
  return useQuery<StoryView[]>({
    queryKey: [queryKeys.DASHBOARD_STORY_VIEW, story_group_id],
    queryFn: async () => {
      const res = await $api2.get(
        endPoints.DASHBOARD_STORY_VIEW + `?story_group_id=${story_group_id}`
      )
      return res.data.data
    },
    enabled: !!story_group_id,
  })
}
