import React from "react"

const BuildersIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 3.9001V12.9201C11.25 13.2501 11.09 13.5501 10.82 13.7401L3.60999 18.7901C3.01999 19.2001 2.17999 18.8801 2.04999 18.1701C1.89999 17.3101 2.09999 16.3001 2.69999 15.2101L5.81999 9.6001L8.75999 4.3101C8.98999 3.9001 9.23999 3.5401 9.49999 3.2301C10.1 2.5301 11.25 2.9801 11.25 3.9001Z"
        fill="#616161"
      />
      <path
        d="M20.39 18.7901L13.18 13.7401C12.91 13.5501 12.75 13.2501 12.75 12.9201V3.9001C12.75 2.9801 13.9 2.5301 14.5 3.2301C14.76 3.5401 15.01 3.9001 15.24 4.3101L18.18 9.6001L21.3 15.2101C21.9 16.3001 22.1 17.3101 21.95 18.1701C21.82 18.8801 20.98 19.2001 20.39 18.7901Z"
        fill="#616161"
      />
      <path
        d="M5.26 19.14L11.41 15.04C11.74 14.82 12.28 14.82 12.61 15.04L18.76 19.14C20.39 20.23 20.12 21.12 18.16 21.12H5.85C3.9 21.11 3.63 20.22 5.26 19.14Z"
        fill="#616161"
      />
    </svg>
  )
}

export default BuildersIcon
