import { RU } from "../constants/locales"
import { removeNaN } from "./removeNaN"

export const localeFormatter = (value: string) => {
  const newValue = removeNaN(value)
  const arr = newValue.split(".")
  if (arr.length === 1) {
    return newValue ? (+newValue).toLocaleString(RU) : ""
  } else if (arr.length === 2) {
    return newValue
      ? (+arr[0]).toLocaleString(RU) + "." + arr[1]?.slice(0, 2)
      : ""
  } else {
    return newValue ? (+arr[0]).toLocaleString(RU) + "." + arr[1] : ""
  }
}
