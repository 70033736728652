import React from "react"
import styles from "./builders.module.scss"
import BuildersRouter from "./routers/BuildersRouter"

const Builders = () => {
  return (
    <div className={styles.container}>
      <BuildersRouter />
    </div>
  )
}

export default Builders
