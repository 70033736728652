export const phoneNumberValidator = () => ({
  required: true,
  message: "",
  validator(_: unknown, value: string) {
    if (value?.replace(/[+()_-\s]/g, "").length !== 12) {
      return Promise.reject()
    }
    return Promise.resolve()
  },
})
