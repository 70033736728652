import React, { useEffect } from "react"
import { Empty, Form, FormInstance, Table } from "antd"

import CreateRoom from "./CreateRoom"
import TrashIcon from "../../../../assets/icons/TrashIcon"
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks"
import { apartmentReducerActions } from "../../../../store/reducers/apartmentReducer"

import styles from "../ApartmentsModal.module.scss"

type Props = {
  formInstance: FormInstance
}

const { Column } = Table

const ApartmentsModalRooms: React.FC<Props> = ({ formInstance }) => {
  const dispatch = useAppDispatch()
  const { deleteRoom } = apartmentReducerActions
  const { rooms } = useAppSelector((state) => state.apartmentReducer)

  // set roooms
  useEffect(() => {
    formInstance.setFieldsValue({
      rooms: rooms,
    })
  }, [formInstance, rooms])

  // handle delete
  const handleDelete = (roomId: number) => {
    dispatch(deleteRoom(roomId))
  }

  return (
    <div className={styles.rooms}>
      {rooms.length > 0 && (
        <Table
          dataSource={rooms}
          pagination={false}
          className={styles.rooms_table}
          rowKey={(record) => record.room_id}
        >
          <Column
            dataIndex="name"
            title="Xonalar nomlanishi"
            render={(value) => <p className={styles.room_name}>{value}</p>}
          />
          <Column
            width={120}
            dataIndex="area"
            title="O‘lchami"
            align="center"
            render={(value) => <p className={styles.room_area}>{value} m²</p>}
          />
          <Column
            width={50}
            align="center"
            render={(record) => (
              <TrashIcon
                className={styles.delete_icon}
                onClickFunc={() => handleDelete(record.room_id)}
              />
            )}
          />
        </Table>
      )}
      <Form.Item
        name="rooms"
        rules={[
          () => ({
            validator(_, value) {
              if (value.length === 0) return Promise.reject()
              return Promise.resolve()
            },
          }),
        ]}
        className={styles.rooms_form_item}
      >
        {rooms.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Xonalar mavjud emas"
          />
        )}
      </Form.Item>
      <CreateRoom formInstance={formInstance} />
    </div>
  )
}

export default ApartmentsModalRooms
