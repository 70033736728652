import { Form, Input } from "antd"
import React from "react"

import { InputMask } from "../../../../common/inputMask/InputMask"
import { PHONE_MASK } from "../../../../utils/constants/inputMasks"
import { phoneNumberValidator } from "../../../../utils/helpers/phoneNumberValidator"

import styles from "../DetailedComplex.module.scss"

const { Item } = Form

const DetailedComplexContact: React.FC = () => {
  return (
    <div className={styles.contact}>
      <p className={styles.little_title}>Kontakt ma’lumotlari</p>
      <div className={styles.body}>
        <Item
          label="Номер телефона"
          name="phone"
          rules={[phoneNumberValidator()]}
        >
          {InputMask({
            mask: PHONE_MASK,
          })}
        </Item>
        <Item label="Facebook (url)" name="facebook">
          <Input />
        </Item>
        <Item label="Instagram (url)" name="instagram">
          <Input />
        </Item>
        <Item label="Telegram" name="telegram">
          <Input />
        </Item>
      </div>
    </div>
  )
}

export default DetailedComplexContact
