import React from "react"

const ActionsIcon: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00033 4.66667C8.73671 4.66667 9.33366 4.06971 9.33366 3.33333C9.33366 2.59695 8.73671 2 8.00033 2C7.26395 2 6.66699 2.59695 6.66699 3.33333C6.66699 4.06971 7.26395 4.66667 8.00033 4.66667Z"
        fill="#464255"
      />
      <path
        d="M8.00033 9.33268C8.73671 9.33268 9.33366 8.73573 9.33366 7.99935C9.33366 7.26297 8.73671 6.66602 8.00033 6.66602C7.26395 6.66602 6.66699 7.26297 6.66699 7.99935C6.66699 8.73573 7.26395 9.33268 8.00033 9.33268Z"
        fill="#464255"
      />
      <path
        d="M8.00033 14.0007C8.73671 14.0007 9.33366 13.4037 9.33366 12.6673C9.33366 11.9309 8.73671 11.334 8.00033 11.334C7.26395 11.334 6.66699 11.9309 6.66699 12.6673C6.66699 13.4037 7.26395 14.0007 8.00033 14.0007Z"
        fill="#464255"
      />
    </svg>
  )
}

export default ActionsIcon
