import { Form, FormInstance, Input, InputRef } from "antd"
import React, { useEffect, useRef, useState } from "react"

import AddIcon from "../../../../assets/icons/AddIcon"
import TickIcon from "../../../../assets/icons/TickIcon"
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks"
import { apartmentReducerActions } from "../../../../store/reducers/apartmentReducer"
import { keyboardKeys } from "../../../../utils/constants/keyboardKeys"

import styles from "../ApartmentsModal.module.scss"

type Props = {
  formInstance: FormInstance
}

const { Item } = Form

const CreateRoom: React.FC<Props> = ({ formInstance }) => {
  const { rooms } = useAppSelector((state) => state.apartmentReducer)
  const dispatch = useAppDispatch()
  const { addRoom } = apartmentReducerActions
  const [enableCreate, setEnableCreate] = useState(false)
  const [name, setName] = useState("")
  const [area, setArea] = useState<number>()
  const nameInputRef = useRef<InputRef | null>(null)
  const [errors, setErrors] = useState<string[]>([])

  // disable create
  useEffect(() => {
    if (rooms.length === 0) {
      setEnableCreate(false)
    }
  }, [rooms.length])

  // handle enable create
  const handleEnableCreate = () => {
    setEnableCreate(true)
  }

  // clear states
  const clearStates = () => {
    setName("")
    setArea(undefined)
    setErrors([])
  }

  // handle submit
  const handleSubmit = () => {
    if (!name || !area) {
      return setErrors(() => {
        const newErr = []

        if (!name) newErr.push("name")
        if (!area) newErr.push("area")

        return newErr
      })
    }

    const room_id = new Date().getTime()

    dispatch(addRoom({ name: name!, area: area!, room_id }))

    formInstance.setFieldsValue({
      rooms: [{ name, area, room_id }],
    })

    clearStates()
    nameInputRef.current?.focus()
  }

  // handle name
  const handleName = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    const value = e?.target.value

    setName(value ?? "")

    if (value && errors.includes("name")) {
      setErrors((prev) => {
        return prev.filter((item) => item !== "name")
      })
    }
  }

  // handle are
  const handleArea = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    const value = e?.target.value

    setArea(value ? +value : undefined)

    if (value && errors.includes("area")) {
      setErrors((prev) => {
        return prev.filter((item) => item !== "area")
      })
    }
  }

  // has error
  const hasError = (fieldName: string) => {
    if (errors.includes(fieldName)) return styles.has_error
  }

  // handle key up
  const handleKeyUp = (
    e: React.KeyboardEvent<HTMLInputElement> | undefined
  ) => {
    if (e?.key === keyboardKeys.ENTER) {
      handleSubmit()
    }
  }

  return (
    <>
      {enableCreate && (
        <div className={styles.rooms_form}>
          <Item className={`${styles.room_name}`}>
            <Input
              value={name}
              onChange={handleName}
              className={hasError("name")}
              onKeyUp={handleKeyUp}
              ref={nameInputRef}
            />
          </Item>
          <Item className={styles.room_area}>
            <Input
              suffix="m²"
              type="number"
              value={area}
              onChange={handleArea}
              onKeyUp={handleKeyUp}
              className={hasError("area")}
            />
          </Item>
          <div onClick={handleSubmit} className={styles.create}>
            <TickIcon />
          </div>
        </div>
      )}
      {!enableCreate && (
        <div
          className={styles.rooms_enable_create}
          onClick={handleEnableCreate}
        >
          <AddIcon />
          <span>Xona qo’shish</span>
        </div>
      )}
    </>
  )
}

export default CreateRoom
