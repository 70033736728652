export const PAGE = "page"
export const MAGAZINE_ID = "article_id"
export const LANGUAGE_ID = "language_id"
export const BUILDER_ID = "builder_id"
export const COMPLEX_ID = "complex_id"
export const BLOCK_ID = "block_id"
export const CITY_ID = "city_id"
export const IMAGE_ID = "image_id"
export const APARTMENT_ID = "apartment_id"
export const NAME = "name"
export const DESCRIPTION = "description"
export const IMAGE = "image"
