import Builders from "../../../components/builders/main/Builders"
import { RouteType } from "../../../utils/models/routeModel"
import BuilderId from "../pages/builder-id/BuilderId"
import { buildersPaths } from "./buildersPaths"

export const buildersRoutes: RouteType[] = [
  {
    path: buildersPaths.INDEX,
    element: Builders,
    isExact: true,
  },
  {
    path: buildersPaths.DETAILED,
    element: BuilderId,
    isExact: true,
  },
]
