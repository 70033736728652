import { Image, Spin } from "antd"
import { RcFile } from "antd/lib/upload"
import React, { useState, useMemo } from "react"
import EyeIcon from "../../../../assets/icons/EyeIcon"
import PreviewTrashIcon from "../../../../assets/icons/PreviewTrashIcon"
import { useAppDispatch } from "../../../../hooks/reduxHooks"
import { useDeleteImageApartment } from "../../../../queries/mutations"
import { apartmentReducerActions } from "../../../../store/reducers/apartmentReducer"
import { ApartmentImageType } from "../../../../utils/models/apartmentModel"

import styles from "../ApartmentsModal.module.scss"

type Props = {
  data: RcFile | ApartmentImageType
  index: number
}

const ImageCont: React.FC<Props> = ({ data, index }) => {
  const dispatch = useAppDispatch()
  const { deleteImage } = apartmentReducerActions
  const [visiblePreview, setVisiblePreview] = useState(false)
  const deleteImageApartment = useDeleteImageApartment(aftreSuccessDelete)

  // open preview
  const handleOpenPreview = () => {
    setVisiblePreview(true)
  }

  // is image file
  const isImageFile = useMemo(
    () => !(data as ApartmentImageType).image_id,
    [data]
  )

  // hondle delete
  const handleDelete = () => {
    if (isImageFile) {
      dispatch(deleteImage(index))
    } else {
      deleteImageApartment.mutate((data as ApartmentImageType).image_id)
    }
  }

  // image source
  const imageSource = () => {
    if (!isImageFile) return (data as ApartmentImageType)?.small as string
    return URL.createObjectURL(data as RcFile)
  }

  // image preview source
  const imagePreviewSource = () => {
    if (!isImageFile) return (data as ApartmentImageType)?.large as string
    return URL.createObjectURL(data as RcFile)
  }

  // after success delete
  function aftreSuccessDelete() {
    dispatch(deleteImage(index))
  }

  return (
    <div className={styles.image} key={index}>
      <Spin spinning={deleteImageApartment.isLoading}>
        <Image
          preview={{
            visible: visiblePreview,
            mask: (
              <div className={styles.mask_cont}>
                <EyeIcon onClickFunc={handleOpenPreview} />
                <PreviewTrashIcon onClickFunc={handleDelete} />
              </div>
            ),
            onVisibleChange: (value) => {
              !value && setVisiblePreview(value)
            },
            src: imagePreviewSource(),
          }}
          src={imageSource()}
          alt="apartment"
        />
      </Spin>
    </div>
  )
}

export default ImageCont
