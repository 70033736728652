import React from "react"
import { Route, Routes } from "react-router-dom"
import { buildersRoutes } from "./buildersRoutes"
import MainLayout from "../../../layouts/mainLayout/MainLayout"
import RequirePermission from "../../../routing/RequirePermission"
import { exactRouteFixer } from "../../../utils/helpers/exactRouteFixer"

const BuildersRouter: React.FC = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        {buildersRoutes.map((item) => (
          <Route
            element={<RequirePermission permission={item.neededPermissions} />}
            key={item.path}
          >
            <Route
              path={exactRouteFixer(item.path, item.isExact)}
              element={<item.element />}
            />
          </Route>
        ))}
      </Route>
    </Routes>
  )
}

export default BuildersRouter
