import IsAuth from "../../components/isAuth/IsAuth"
import Builders from "../../pages/builders/Builders"
import { RouteType } from "../../utils/models/routeModel"
import ComplexesRouter from "../complexes/ComplexesRouter"
import HistoryRouter from "../story/StoryRouter"
import MagazinesRouter from "../magazines/MagazinesRouter"
import { rootPaths } from "./rootPaths"

export const rootRoutes: RouteType[] = [
  {
    path: rootPaths.INDEX,
    element: IsAuth,
    isExact: true,
  },
  {
    path: rootPaths.RESIDENTAL_COMPLEXES,
    element: ComplexesRouter,
    isExact: false,
  },
  {
    path: rootPaths.MAGAZINES,
    element: MagazinesRouter,
    isExact: false,
  },
  {
    path: rootPaths.BUILDERS,
    element: Builders,
    isExact: false,
  },
  {
    path: rootPaths.STORY,
    element: HistoryRouter,
    isExact: false,
  },
]
