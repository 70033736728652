import {
  Button,
  Form,
  Image,
  Input,
  Select,
  Spin,
  Upload,
  UploadFile,
} from "antd"
import { UploadChangeParam } from "antd/lib/upload"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import "react-quill/dist/quill.snow.css"

import ArrowLeftIcon from "../../../assets/icons/ArrowLeftIcon"
import EyeIcon from "../../../assets/icons/EyeIcon"
import SelectSuffixIcon from "../../../assets/icons/SelectSuffixIcon"
import TrashIcon from "../../../assets/icons/TrashIcon"
import UploadImageIcon from "../../../assets/icons/UploadImageIcon"
import styles from "./DetailedMagazine.module.scss"

import {
  useCreateMagazine,
  useDeleteMagazine,
  useUpdateMagazine,
} from "../../../queries/mutations"
import { useCategory, useGetOneMagazine } from "../../../queries/queries"
import { rootPaths } from "../../../routing/root/rootPaths"
import { STRING } from "../../../utils/constants/types"
import { PRIMARY } from "../../../utils/constants/buttonTypes"
import JournalDescription from "./MagazineDescription"
import { statusNames } from "../../../utils/constants/statuses"

type FormType = {
  category_id: number
  description: string
  language_id: number
  name: string
  image: Blob | undefined | string
}

const DetailedMagazine: React.FC = () => {
  const params = useParams()
  const navigate = useNavigate()

  const { Item } = Form
  const { Option } = Select
  const { Dragger } = Upload
  const [formInstance] = Form.useForm()

  const deleteMagazineMutation = useDeleteMagazine(handleAfterSuccess)
  const { data, isLoading } = useGetOneMagazine(Number(params.id))
  const createMagazine = useCreateMagazine(handleAfterSuccess)
  const updateMagazine = useUpdateMagazine(handleAfterSuccess)
  const { data: categories } = useCategory()

  const [articleImage, setArticleImage] = useState<Blob | undefined | string>()
  const [status, setStatus] = useState<string>(data?.status ?? "")

  // initial fields
  useEffect(() => {
    if (data) {
      formInstance.setFieldsValue({
        ...data,
        meta_keywords: data?.meta_keywords
          ? data?.meta_keywords?.split(",")
          : [],
      })
    }
  }, [data, formInstance])

  // clear fields
  useEffect(() => {
    return () => {
      formInstance.resetFields()
    }
  }, [formInstance])

  // go back
  const goBack = () => {
    navigate(-1)
  }

  // handle after success
  function handleAfterSuccess() {
    navigate(rootPaths.MAGAZINES)
  }

  // delete article
  const handleDelete = () => {
    deleteMagazineMutation.mutate({ magazineId: params.id! })
  }

  // delete button
  const deleteButton = () => {
    if (params?.id) {
      return (
        <Button onClick={handleDelete}>
          <TrashIcon /> O'chirish
        </Button>
      )
    }
  }

  // data take a image
  useEffect(() => {
    if (!articleImage && data) {
      setArticleImage(data?.image)
    }
  }, [data, articleImage])

  // clear image and status
  useEffect(() => {
    return () => {
      setArticleImage(undefined)
      setStatus("")
    }
  }, [])

  // handle file from event
  const handleFileFromEvent = (e: UploadChangeParam<UploadFile>) => {
    const file = e?.file?.originFileObj

    if (file) return file
  }

  // handle change image
  const handleChange = (e: UploadChangeParam<UploadFile>) => {
    const image = e?.file?.originFileObj

    setArticleImage(image as any)
  }

  // handle image click
  const handleImageClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e?.stopPropagation()
  }

  // image source
  const imageSource = () => {
    if (typeof articleImage === STRING) return articleImage! as string
    return URL.createObjectURL(articleImage! as Blob)
  }

  // on finish
  const onFinish = (fields: FormType) => {
    const formData = new FormData()
    let key: keyof typeof fields
    // status
    formData.append("status", status)
    // remove image
    if (typeof fields.image === STRING) delete fields.image
    for (key in fields) {
      if (Array.isArray(fields[key])) {
        formData.append(key, (fields[key] as unknown as Array<string>)?.join())
      } else {
        formData.append(key, fields[key] as string | Blob)
      }
    }
    if (params?.id) {
      formData.append("article_id", params?.id!)
      updateMagazine.mutate(formData)
    } else createMagazine.mutate(formData)
  }

  // finish failed
  const onFinishFailed = (e: any) => {
    document
      .getElementById(e.errorFields[0].name[0] as string)
      ?.scrollIntoView({ block: "center", behavior: "smooth" })
  }

  // print article
  const printArticle = async (status: string) => {
    setStatus(status)

    formInstance.submit()
  }

  return (
    <div className={styles.container}>
      <div className={styles.container_top}>
        <Button onClick={goBack}>
          <ArrowLeftIcon /> "Jurnal"ga qaytish
        </Button>
        {deleteButton()}
      </div>
      <div className={styles.container_bottom}>
        <Spin spinning={isLoading || deleteMagazineMutation.isLoading}>
          <Form
            form={formInstance}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className={styles.form}
          >
            <Item
              name="language_id"
              label="Maqola tili"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Select
                className={styles.form_select}
                placeholder="Maqola tilini kiriting"
                suffixIcon={<SelectSuffixIcon />}
              >
                <Option value={1} key={1}>
                  O'zbek tili
                </Option>
                <Option value={2} key={2}>
                  Rus tili
                </Option>
                <Option value={3} key={3}>
                  Ingliz tili
                </Option>
              </Select>
            </Item>
            <Item
              name="category_id"
              label="Maqola turi"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Select
                placeholder="Maqola turini kiriting"
                className={styles.form_select}
                suffixIcon={<SelectSuffixIcon />}
                defaultValue={data?.category_id}
              >
                {categories?.map((item, index) => (
                  <Option key={index} value={item.category_id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Item>
            <Item
              name="image"
              label="Maqola yuzi (rasmi)"
              getValueFromEvent={handleFileFromEvent}
              className={styles.form_image}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Dragger
                className={
                  !articleImage ? styles.dragger : styles.dragger_with_image
                }
                showUploadList={false}
                onChange={handleChange}
                customRequest={() => null}
                accept=".jpg, .jpeg, .png"
              >
                {!articleImage ? (
                  <>
                    <UploadImageIcon />
                    <p className={styles.description}>
                      Rasmni shu yerga yuboring
                    </p>
                    <p className={styles.accept}>
                      Yuborish mumkin fayllar: PNG, JPEG, JPG
                    </p>
                    <Button>Fayl tanlash</Button>
                    <p className={styles.max_size}>Maksimal hajm: 10 Mb</p>
                  </>
                ) : (
                  <Image
                    onClick={handleImageClick}
                    preview={{
                      mask: <EyeIcon />,
                    }}
                    src={imageSource()}
                  />
                )}
              </Dragger>
            </Item>
            <Item
              name="name"
              label="Maqola nomi"
              className={styles.form_name}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Input />
            </Item>
            <JournalDescription />
            <p className={styles.little_title}>SEO</p>
            <Item
              label="Meta taglar"
              name="meta_keywords"
              className={styles.formItemFull}
            >
              <Select mode="tags" open={false} />
            </Item>

            <Item
              label="Meta description"
              name="meta_description"
              className={styles.formItemFull}
            >
              <Input.TextArea
                autoSize={{ minRows: 2, maxRows: 3 }}
                maxLength={155}
                minLength={70}
              />
            </Item>
            <div className={styles.form_save}>
              <Button
                type={PRIMARY}
                loading={createMagazine.isLoading && updateMagazine.isLoading}
                className={styles.form_save_print}
                onClick={() => printArticle(statusNames.ACTIVE)}
              >
                Chop etish
              </Button>
              <Button
                onClick={() => printArticle(statusNames.PASSIVE)}
                className={styles.form_save_btn}
                loading={createMagazine.isLoading && updateMagazine.isLoading}
              >
                Saqlash
              </Button>
            </div>
          </Form>
        </Spin>
      </div>
    </div>
  )
}

export default DetailedMagazine
