import { Skeleton } from "antd"
import React from "react"

import styles from "./BuilderLoader.module.scss"

const BuilderLoader: React.FC = () => {
  return (
    <div className={styles.builder_loader}>
      <Skeleton active />
    </div>
  )
}

export default BuilderLoader
