import React from "react"
import { NavLink, Outlet } from "react-router-dom"
import BuildersIcon from "../../assets/icons/BuildersIcon"
import ComplexesIcon from "../../assets/icons/ComplexesIcon"
import MagazinesIcon from "../../assets/icons/MagazinesIcon"

import logo from "../../assets/images/logo.png"
import { rootPaths } from "../../routing/root/rootPaths"

import styles from "./MainLayout.module.scss"
import DirectNormalIcon from "../../assets/icons/DirectNormalIcon"

const MainLayout: React.FC = () => {
  // navlinks
  const navlinks = [
    {
      icon: <ComplexesIcon />,
      label: "Turar-joy majmualari",
      path: rootPaths.RESIDENTAL_COMPLEXES,
    },
    {
      icon: <MagazinesIcon />,
      label: "Jurnal",
      path: rootPaths.MAGAZINES,
    },
    {
      icon: <BuildersIcon />,
      label: "Quruvchilar",
      path: rootPaths.BUILDERS,
    },
    {
      icon: <DirectNormalIcon />,
      label: "Hikoyalar",
      path: rootPaths.STORY,
    },
  ]

  return (
    <div className={styles.layout}>
      <div className={styles.layout_sidebar}>
        <div className={styles.top}>
          <img alt="logotype" src={logo} />
          <p>
            <span>UYSOT</span>
            <span>DASHBOARD</span>
          </p>
        </div>
        <div className={styles.center}>
          {navlinks?.map((item, index) => (
            <NavLink key={index} to={item.path} className={`link${index}`}>
              {item.icon}
              <p>{item.label}</p>
            </NavLink>
          ))}
        </div>
        {/* <div className={styles.bottom}>exit</div> */}
      </div>
      <div className={styles.layout_body}>
        <Outlet />
      </div>
    </div>
  )
}

export default MainLayout
