import React from "react"
import { useNavigate } from "react-router-dom"
import { rootPaths } from "../../../routing/root/rootPaths"

import { BuilderType } from "../../../utils/models/buildersModel"

import styles from "./BuilderCard.module.scss"

const BuilderCard: React.FC<BuilderType> = ({
  address,
  builder_id,
  description_ru,
  description_uz,
  image,
  name,
  phones,
}) => {
  const navigate = useNavigate()

  // to detailed
  const toDetailed = () => {
    navigate(`${rootPaths.BUILDERS}/${builder_id}`)
  }

  return (
    <div className={styles.builder_card} onClick={toDetailed}>
      <div className={styles.builder_card_left}>
        <h3 className={styles.name}>{name}</h3>
        <p className={styles.address}>{address}</p>
        <p className={styles.description}>{description_uz}</p>
        {phones?.map((item, index) => (
          <p className={styles.phone} key={index}>
            {item}
          </p>
        ))}
      </div>
      <div className={styles.builder_card_right}>
        <img alt={name} src={image} />
      </div>
    </div>
  )
}

export default BuilderCard
