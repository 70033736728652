import { Form, FormInstance, Input } from "antd"
import React from "react"

type Props = {
  formInstance: FormInstance
}

const { Item } = Form

const ApartmentsModalFloor: React.FC<Props> = ({ formInstance }) => {
  // handle change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    const value = e?.target?.value || ""

    formInstance.setFieldsValue({
      floor: value.replace(/[^\d,-]/g, ""),
    })
  }

  return (
    <Item label="Qavat" name="floor" rules={[{ required: true, message: "" }]}>
      <Input onChange={handleChange} placeholder="1,3,5-8" />
    </Item>
  )
}

export default ApartmentsModalFloor
