import React from 'react'
import { Outlet } from 'react-router-dom'

import { permissions } from '../utils/enums/permissions'
import { hasPermission } from '../utils/helpers/hasPermission'

type props = {
  permission: permissions | undefined
}

const RequirePermission: React.FC<props> = ({ permission }) => {
  if (!permission) return <Outlet />

  if (hasPermission(permission)) {
    return <Outlet />
  }
  return <h1>NOT FOUND</h1>
}

export default RequirePermission
