import Story from "../../pages/story/Story"
import StoryChilds from "../../pages/story/pages/story-childs/StoryChilds"
import StoryChildId from "../../pages/story/pages/story-childs/pages/story-child-id/StoryChildId"
import StoryId from "../../pages/story/pages/story-id/StoryId"
import { RouteType } from "../../utils/models/routeModel"
import { storyPaths } from "./storyPaths"

export const storyRoutes: RouteType[] = [
  {
    path: storyPaths.INDEX,
    element: Story,
    isExact: true,
  },
  {
    path: storyPaths.STORY_ID,
    element: StoryId,
    isExact: true,
  },
  {
    path: storyPaths.STORY_ID_NAME,
    element: StoryChilds,
    isExact: true,
  },
  {
    path: storyPaths.STORY_ID_NAME_STORY_ID,
    element: StoryChildId,
    isExact: true,
  },
]
