import { Checkbox, Form, FormInstance, Input } from "antd"
import React, { useState, useEffect, useMemo } from "react"
import { keyboardKeys } from "../../../../utils/constants/keyboardKeys"
import { isEmptyArr } from "../../../../utils/helpers/isEmptyArr"

import styles from "../DetailedComplex.module.scss"

type Props = {
  formInstance: FormInstance
  data: string[] | undefined
}

const { Item } = Form
const { Group } = Checkbox

const DetailedComplexFeatures: React.FC<Props> = ({ formInstance, data }) => {
  const [otherFeatures, setOtherFeatures] = useState<string[]>([])

  // features
  const features = useMemo(
    () => [
      {
        title: "Xavfsizlik",
        items: [
          "Видеонаблюдение",
          "Домофон",
          "Консьерж",
          "Закрытая территория",
          "Кодовая дверь",
        ],
      },
      {
        title: "Avto turargoh",
        items: [
          "Во дворе",
          "Со шлагбаумом",
          "Подземная",
          "Есть гараж",
          "Охраняемая",
        ],
      },
      {
        title: "TJM hovlisida",
        items: ["Детская площадка", "Спортивная площадка"],
      },
      {
        title: "Infrastruktura",
        items: ["Школа", "Парк", "Детский сад", "Фитнес", "Торговый центр"],
      },
    ],
    []
  )

  // initial other features
  const initialOtherFeatures = useMemo(
    () =>
      data?.filter((item) => {
        const all: string[] = []

        // all
        features.forEach((item) => all.push(...item.items))

        return !all.includes(item)
      }),
    [data, features]
  )

  // initial other features
  useEffect(() => {
    if (
      isEmptyArr(otherFeatures) &&
      data &&
      !isEmptyArr(initialOtherFeatures)
    ) {
      const all: string[] = []
      const others: string[] = []

      // all
      features.forEach((item) => all.push(...item.items))

      // others
      data?.forEach((item) => {
        if (!all.includes(item)) {
          others.push(item)
        }
      })

      setOtherFeatures(others)
    }
  }, [data, features, otherFeatures, initialOtherFeatures])

  // handle key up
  const handleKeyUp = (
    e: React.KeyboardEvent<HTMLInputElement> | undefined
  ) => {
    const value = formInstance.getFieldValue("add_feature")

    if (e?.key === keyboardKeys.ENTER && value) {
      setOtherFeatures([...otherFeatures, value])
      formInstance.resetFields(["add_feature"])
    }
  }

  // handle key down
  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement> | undefined
  ) => {
    if (e?.key === keyboardKeys.ENTER) {
      e?.preventDefault()
    }
  }

  return (
    <div className={styles.features}>
      <p className={styles.little_title}>
        Turar-joy majmuasining o’ziga xosligi
      </p>
      <div className={styles.features_body}>
        <Item
          className={styles.add_feature}
          name="add_feature"
          label="Yangi qo’shish"
        >
          <Input onKeyUp={handleKeyUp} onKeyDown={handleKeyDown} />
        </Item>
        <Item name="specifices" initialValue={[]}>
          <Group>
            {otherFeatures.length > 0 && (
              <div className={styles.checkbox_group}>
                <p className={styles.title}>Boshqalar</p>
                <div className={styles.checkbox_cont}>
                  {otherFeatures.map((item) => (
                    <Checkbox value={item} key={item}>
                      <span
                        className={`${styles.checkbox_label} checkbox_label`}
                      >
                        {item}
                      </span>
                    </Checkbox>
                  ))}
                </div>
              </div>
            )}
            {features.map((feature, index) => (
              <div className={styles.checkbox_group} key={index}>
                <p className={styles.title}>{feature.title}</p>
                <div className={styles.checkbox_cont}>
                  {feature.items.map((item) => (
                    <Checkbox value={item} key={item}>
                      <span
                        className={`${styles.checkbox_label} checkbox_label`}
                      >
                        {item}
                      </span>
                    </Checkbox>
                  ))}
                </div>
              </div>
            ))}
          </Group>
        </Item>
      </div>
    </div>
  )
}

export default DetailedComplexFeatures
