import { Form, UploadFile, Upload, Button, Image } from "antd"
import { UploadChangeParam } from "antd/lib/upload"
import React, { useEffect, useState } from "react"
import EyeIcon from "../../../../assets/icons/EyeIcon"
import UploadImageIcon from "../../../../assets/icons/UploadImageIcon"
import { STRING } from "../../../../utils/constants/types"
import { BuilderType } from "../../../../utils/models/buildersModel"

import styles from "../DetailedBuilder.module.scss"

type Props = {
  data: BuilderType | undefined
}

const { Item } = Form
const { Dragger } = Upload

const DetailedBuilderImage: React.FC<Props> = ({ data }) => {
  const [image, setImage] = useState<Blob | undefined | string>()

  // initial fields
  useEffect(() => {
    if (!image && data) {
      setImage(data?.image)
    }
  }, [data, image])

  // clear fields
  useEffect(() => {
    return () => {
      setImage(undefined)
    }
  }, [])

  // handle file from event
  const handleFileFromEvent = (e: UploadChangeParam<UploadFile>) => {
    const file = e?.file?.originFileObj

    if (file) return file
  }

  // handle change
  const handleChange = (e: UploadChangeParam<UploadFile>) => {
    const image = e?.file?.originFileObj

    setImage(image)
  }

  // handle image click
  const handleImageClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e?.stopPropagation()
  }

  // image source
  const imageSource = () => {
    if (typeof image === STRING) return image as string
    return URL.createObjectURL(image! as Blob)
  }

  return (
    <Item
      name="image"
      label="Logotip"
      rules={[
        () => ({
          validator(_, value) {
            if (!value) return Promise.reject()
            return Promise.resolve()
          },
        }),
      ]}
      getValueFromEvent={handleFileFromEvent}
    >
      <Dragger
        className={!image ? styles.dragger : styles.dragger_with_image}
        showUploadList={false}
        onChange={handleChange}
        customRequest={() => null}
        accept=".jpg, .jpeg, .png"
      >
        {!image ? (
          <>
            <UploadImageIcon />
            <p className={styles.description}>Drag and drop photos here</p>
            <p className={styles.accept}>
              Yuborish mumkin fayllar: PNG, JPEG, JPG
            </p>
            <Button>Fayl tanlash</Button>
            <p className={styles.max_size}>Maksimal hajm: 10 Mb</p>
          </>
        ) : (
          <Image
            onClick={handleImageClick}
            preview={{
              mask: <EyeIcon />,
            }}
            src={imageSource()}
          />
        )}
      </Dragger>
    </Item>
  )
}

export default DetailedBuilderImage
