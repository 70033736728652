import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import { setupStore } from "./store/store"
import RootRouter from "./routing/root/RootRouter"

import "antd/dist/antd.variable.min.css"
import "./Common.scss"
import "./Main.scss"

const queryClient = new QueryClient()

const App: React.FC = () => {
  const store = setupStore()

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <RootRouter />
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  )
}

export default App
