import React from "react"
import styles from "./storyChildsHeader.module.scss"
import { useParams } from "react-router-dom"

const StoryChildsHeader = () => {
  const params = useParams()
  return <h1 className={styles.title}>{params?.name}</h1>
}

export default StoryChildsHeader
