import { Form, Input } from "antd"
import React from "react"

import styles from "../DetailedComplex.module.scss"

const { Item } = Form
const { TextArea } = Input

const DetailedComplexAbout: React.FC = () => {
  return (
    <div className={styles.about}>
      <p className={styles.little_title}>Turar-joy majmuasi haqida</p>
      <div className={styles.about_body}>
        <Item
          label="Uz"
          className={styles.uz}
          name="information_uz"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <TextArea />
        </Item>
        <Item
          label="Ru"
          className={styles.ru}
          name="information_ru"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <TextArea />
        </Item>
      </div>
    </div>
  )
}

export default DetailedComplexAbout
