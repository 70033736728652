import { Button, Image, Upload } from "antd"
import styles from "./imageUpload.module.scss"
import EyeIcon from "../../assets/icons/EyeIcon"
import UploadImageIcon from "../../assets/icons/UploadImageIcon"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useEffect, useState } from "react"

interface IProps {
  onChange?: (value: any) => void
  value?: any
  accept?: string
}

const { Dragger } = Upload

const ImageUpload = ({
  onChange,
  value,
  accept = ".jpg, .jpeg, .png",
}: IProps) => {
  const [fileType, setFileType] = useState("")
  const type = fileType.split("/")[0]

  const image = () => {
    if (value) {
      if (typeof value === "string") return value! as string
      return URL.createObjectURL(value! as Blob)
    } else {
      return ""
    }
  }

  return (
    <>
      <Dragger
        className={!value ? styles.dragger : styles.dragger_with_image}
        showUploadList={false}
        onChange={(info) => {
          setFileType(info.file.originFileObj?.type || "")
          onChange && onChange(info.file.originFileObj)
        }}
        customRequest={() => null}
        accept={accept}
      >
        {!value ? (
          <>
            <UploadImageIcon />
            <p className={styles.description}>Rasmni shu yerga yuboring</p>
            <p className={styles.accept}>
              Yuborish mumkin fayllar: PNG, JPEG, JPG
            </p>
            <Button>Fayl tanlash</Button>
            <p className={styles.max_size}>Maksimal hajm: 10 Mb</p>
          </>
        ) : (
          <>
            {type === "video" ? (
              <video
                className={styles.video}
                onClick={(e) => e?.stopPropagation()}
                controls
              >
                <source src={image()} type={fileType} />
              </video>
            ) : (
              <Image
                onClick={(e) => e?.stopPropagation()}
                preview={{
                  mask: <EyeIcon />,
                }}
                src={image()}
              />
            )}
          </>
        )}
      </Dragger>
      {/* <img src={data && URL.createObjectURL(data)} /> */}
    </>
  )
}

export default ImageUpload
