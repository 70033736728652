export const queryKeys = {
  IS_AUTH: "is_auth",
  RESIDENTIAL_COMPLEXES: "residential_complexes",
  ONE_RESIDENTIAL_COMPLEX: "one_residential_complex",
  MAGAZINE: "article",
  CATEGORY: "category",
  BUILDERS: "builders",
  ONE_BUILDER: "one_builder",
  BLOCKS: "blocks",
  CLASSES: "classes",
  APARTMENTS: "apartments",
  CITIES: "cities",
  DISTRICTS: "districts",
  STATISTICS: "statistics",
  PAYMENT_METHODS: "payment_methods",
  REGION_LIST: "REGION_LIST",
  REGION_DISTRICTSWITHOUTCOUNTCOMPLEXES_ID:
    "REGION_DISTRICTSWITHOUTCOUNTCOMPLEXES_ID",
  COMPLEX_NEARESTPLACES: "complex_nearestplaces"
}
