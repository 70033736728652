import axios from "axios"

export const BASE_URL = "https://marketplace.uysot.uz"

const $api = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL,
})

export const $api2 = axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
})

export default $api
