import React from "react"

type Props = {
  className?: string
}

const BlockTrashIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8359 5.58032C11.6796 5.36271 9.50346 5.25391 7.33725 5.25391C6.05137 5.25391 4.76549 5.32314 3.4895 5.45173L2.16406 5.58032"
        stroke="#2c2c2c"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.73535 4.92882L5.87383 4.07816C5.97274 3.46489 6.05187 3 7.14981 3H8.85113C9.94907 3 10.0282 3.48467 10.1271 4.07816L10.2656 4.91893"
        stroke="#2c2c2c"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4412 5.66016L12.0158 12.1885C11.9466 13.2073 11.8873 13.9986 10.0771 13.9986H5.91287C4.10274 13.9986 4.04339 13.2073 3.97415 12.1885L3.54883 5.66016"
        stroke="#2c2c2c"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BlockTrashIcon
