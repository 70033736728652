import { Button, Empty, Spin } from "antd"
import { parse } from "query-string"
import React, { useState } from "react"
import { useLocation } from "react-router-dom"

import AddIcon from "../../../assets/icons/AddIcon"
import CustomPagination from "../../../common/customPagination/CustomPagination"
import { useGetApartments } from "../../../queries/queries"
import { PAGE } from "../../../utils/constants/queryParamsKeys"
import { isEmptyArr } from "../../../utils/helpers/isEmptyArr"
import { ApartmentType } from "../../../utils/models/apartmentModel"
import ApartmentCard from "../../apartmentCard/ApartmentCard"
import ApartmentsModal from "../apartmentsModal/ApartmentsModal"

import styles from "../Blocks.module.scss"

type Props = {
  blockId: number | undefined
}

const Apartments: React.FC<Props> = ({ blockId }) => {
  const location = useLocation()
  const page = parse(location.search)[PAGE]
  const { data, isLoading } = useGetApartments(blockId, page as string)
  const [visibleModal, setVisibleModal] = useState(false)
  const [modalData, setModalData] = useState<ApartmentType | null>(null)

  // handle open modal
  const handleOpenModal = () => {
    setVisibleModal(true)
  }

  return (
    <>
      <div className={styles.apartments}>
        <div className={styles.apartments_header}>
          <p className={styles.little_title}>Xonadonlar</p>
          <Button onClick={handleOpenModal}>
            <AddIcon />
            <span>Xonadon qo’shish</span>
          </Button>
        </div>
        <Spin spinning={isLoading}>
          <div className={styles.apartments_body}>
            {isEmptyArr(data?.data) ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Xonadonlar mavjud emas"
              />
            ) : (
              data?.data?.map((apartment) => (
                <ApartmentCard
                  key={apartment.apartment_id}
                  data={apartment}
                  setVisibleModal={setVisibleModal}
                  setModalData={setModalData}
                />
              ))
            )}
          </div>
          <CustomPagination total={data?.total} />
        </Spin>
      </div>
      <ApartmentsModal
        visible={visibleModal}
        setVisible={setVisibleModal}
        blockId={blockId}
        data={modalData}
        setData={setModalData}
      />
    </>
  )
}

export default Apartments
