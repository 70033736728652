import { Button } from "antd"
import React from "react"
import { useNavigate } from "react-router-dom"

import PlusIcon from "../../../assets/icons/PlusIcon"
import uysotIframe from "../../../assets/images/uysotIframe.png"
import ComplexCard from "../../../common/complexCard/ComplexCard"
import { useGetComplexes } from "../../../queries/queries"
import { rootPaths } from "../../../routing/root/rootPaths"
import { createNewArr } from "../../../utils/helpers/createNewArr"
import ComplexLoader from "../loader/ComplexLoader"

import styles from "./Complexes.module.scss"

const Complexes: React.FC = () => {
  const { data, isLoading } = useGetComplexes()
  const navigate = useNavigate()

  // to create complex
  const toCreateComplex = () => {
    navigate(`${rootPaths.RESIDENTAL_COMPLEXES}/new`)
  }

  // navigate to uysot
  const navigateToUysot = () => {
    window.location.replace(
      "https://app.uysot.uz/integration/dialog?redirect_url=https://marketplace.uysot.uz/api/uysot/token&extra_id=1"
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.container_top}>
        <div className={styles.left}>
          <h2>
            <span>UYSOT </span>
            tizimidan foydalanasizmi?
          </h2>
          <p>
            Uysot.uz tizimida mavjud barcha ma'lumotlarni tez <br /> va oson
            yuklab olish uchun bosing
          </p>
          <div className={styles.buttons_cont}>
            <Button className={styles.login} onClick={navigateToUysot}>
              Kirish
            </Button>
            <Button className={styles.more}>To'liq ma'lumot olish</Button>
          </div>
        </div>
        <div className={styles.right}>
          <img src={uysotIframe} alt="uysot-iframe" />
        </div>
      </div>
      <div className={styles.container_bottom}>
        <div className={styles.container_bottom_header}>
          <h1>Turar-joy majmuasi qo’shish</h1>
        </div>
        <div className={styles.container_bottom_body}>
          <div className={styles.new_complex} onClick={toCreateComplex}>
            <PlusIcon />
            <p>Yangi yaratish</p>
          </div>
          {isLoading
            ? createNewArr(9).map((_, index) => <ComplexLoader key={index} />)
            : data?.map((item) => (
                <ComplexCard key={item.complex_id} {...item} />
              ))}
        </div>
      </div>
    </div>
  )
}

export default Complexes
