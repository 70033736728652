import { ConfigProvider } from 'antd'
import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import { colors } from './utils/constants/colors'

ConfigProvider.config({
  theme: {
    primaryColor: colors.PRIMARY_COLOR,
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
