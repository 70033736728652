import { Col, Form, FormInstance, Input, Row, Select } from "antd"
import React, { useState, useEffect } from "react"

import SelectSuffixIcon from "../../../../assets/icons/SelectSuffixIcon"
import { useGetCities, useGetDistricts } from "../../../../queries/queries"

import styles from "../DetailedComplex.module.scss"

type Props = {
  city_id: number | undefined
  formInstance: FormInstance
}

const { Item } = Form
const { Option } = Select

const Address: React.FC<Props> = ({ city_id, formInstance }) => {
  const [activeCity, setActiveCity] = useState<number>()
  const { data: cities, isLoading: isLoadingCities } = useGetCities()
  const { data: districts, isLoading: isLoadingDistricts } =
    useGetDistricts(activeCity)

  // initial active city
  useEffect(() => {
    if (!activeCity && city_id) setActiveCity(city_id)
  }, [activeCity, city_id])

  // handle active city
  const handleActiveCity = (cityId: number) => {
    formInstance.resetFields(["district_id"])
    setActiveCity(cityId)
  }

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col span={12}>
          <Item
            label="Viloyat"
            name="city_id"
            className={styles.third_row_item}
          >
            <Select
              suffixIcon={<SelectSuffixIcon />}
              loading={isLoadingCities}
              onChange={handleActiveCity}
            >
              {cities?.map((city) => (
                <Option value={city.city_id} key={city.city_id}>
                  {city.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Shahar"
            name="district_id"
            className={styles.third_row_item}
          >
            <Select
              suffixIcon={<SelectSuffixIcon />}
              loading={isLoadingDistricts}
            >
              {districts?.map((district) => (
                <Option value={district.district_id} key={district.district_id}>
                  {district.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Turar-joy majmuasining manzili"
            name="address"
            rules={[{ required: true, message: "" }]}
            className={styles.third_row_item}
          >
            <Input />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Turar-joy majmuasining manzili (Ru)"
            name="address_ru"
            rules={[{ required: true, message: "" }]}
            className={styles.third_row_item}
          >
            <Input />
          </Item>
        </Col>
      </Row>
    </>
  )
}

export default Address
