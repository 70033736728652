import { addControllerPrefix } from "../helpers/addControllerPrefix"

const controllers = {
  auth: "/auth",
  complex: "/complex",
  magazine: "/article",
  builder: "/builder",
  block: "/block",
  class: "/class",
  apartment: "/apartment",
  city: "/complex/city",
  paymentMethod: "/payment/method",
  nearestplaces: "/nearestplaces"
}

// auth
const auth = {
  INDEX: "",
}
export const authEndpoints: typeof auth = addControllerPrefix(
  auth,

  controllers.auth
)

// complex
const complex = {
  GET: "/view",
  CREATE: "/create",
  UPDATE: "/update",
  DELETE: "/delete",
  IMAGE_UPLOAD: "/image/upload",
  IMAGE_DELETE: "/image/delete",
  STATUS_CHANGE: "/status/change",
  STATISTICS: "/statistic?complex_id=",
}
export const complexEndpoints: typeof complex = addControllerPrefix(
  complex,
  controllers.complex
)

//nearestplaces
const nearestplaces = {
  GET: "/view",
  CREATE: "/create",
  DELETE: "/delete",
}

export const nearestplacesEndpoints: typeof nearestplaces = addControllerPrefix(
  nearestplaces,
  controllers.nearestplaces
)

// magazine
const magazine = {
  GET: "/view",
  DELETE: "/delete",
  CREATE: "/create",
  UPDATE: "/update",
  STATUS: "/status/change",
  CATEGORY: "/category",
}
export const magazineEndpoints: typeof magazine = addControllerPrefix(
  magazine,
  controllers.magazine
)

// builder
const builder = {
  GET: "/view",
  CREATE: "/create",
  DELETE: "/delete",
  UPDATE: "/update",
}
export const builderEndpoints: typeof builder = addControllerPrefix(
  builder,
  controllers.builder
)

// block
const block = {
  GET: "/view",
  CREATE: "/create",
  DELETE: "/delete",
  UPDATE: "/update",
}
export const blockEndpoints: typeof block = addControllerPrefix(
  block,
  controllers.block
)

// classes
const classes = {
  SELECT: "/select",
}
export const classEndpoints: typeof classes = addControllerPrefix(
  classes,
  controllers.class
)

// apartment
const apartment = {
  GET: "/view",
  CREATE: "/create",
  DELETE: "/delete",
  UPDATE: "/update",
  IMAGE_UPLOAD: "/image/upload",
  IMAGE_DELETE: "/image/delete",
}
export const apartmentEndpoints: typeof apartment = addControllerPrefix(
  apartment,
  controllers.apartment
)

// city
const city = {
  INDEX: "",
}
export const cityEndpoints: typeof city = addControllerPrefix(
  city,
  controllers.city
)

// payment method
const paymentMethod = {
  SELECT: "/select",
}
export const paymentMethodEndpoints: typeof paymentMethod = addControllerPrefix(
  paymentMethod,
  controllers.paymentMethod
)
